import { formatDateTime, BASE_PATHS, type DateTimeFormatValue, template } from '@belong/common';
import { PropertyOnboardingModel } from 'api/models';
import { QUERY_PARAMS } from 'consts/in-person-visits';
import type { Location } from 'history';
import { HOMEOWNER_ONBOARDING_STRINGS } from 'strings/homeowner-onboarding.strings';

// This won't be permantent. It should be moved to a hook once sign-up flow is migrated to Next.js
export function parseCalendlyDateFromURL(location: Location) {
  const parsedURL = new URLSearchParams(location.search);
  const calendlyDateURLParam = parsedURL.get(QUERY_PARAMS.CALENDLY_DATE);

  const calendlyDate = new Date(calendlyDateURLParam);

  const format: DateTimeFormatValue = calendlyDate.getMinutes() ? "MMMM do 'at' h':'m aaa" : "MMMM do 'at' h aaa";

  return formatDateTime({
    dateTime: calendlyDate,
    format,
  });
}

export function getSaveAndExitPathFromOnboardingProperties({
  onboardingPropertiesData,
}: Record<'onboardingPropertiesData', PropertyOnboardingModel[]>) {
  if (onboardingPropertiesData?.length > 1) {
    return BASE_PATHS.HOMEOWNER_SETUP_FLOW;
  }

  if (onboardingPropertiesData?.length === 1) {
    return `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${onboardingPropertiesData[0].basicInfo.propertyId}`;
  }

  return BASE_PATHS.HOMEOWNER;
}

type GetSelfServeModalTitleParams = {
  isPreQualified?: boolean;
  wantsToBeCalled?: boolean;
  user?: {
    firstName: string;
  };
};

export function getSelfServeModalTitle({ isPreQualified, wantsToBeCalled, user }: GetSelfServeModalTitleParams) {
  if (isPreQualified && wantsToBeCalled) {
    return template(HOMEOWNER_ONBOARDING_STRINGS['self-serve-modal-pre-qualified-self-serve-connect-now'], {
      firstName: user?.firstName,
    });
  }

  if (isPreQualified) {
    return template(HOMEOWNER_ONBOARDING_STRINGS['self-serve-modal-pre-qualified-self-serve-not-connect-now'], {
      firstName: user?.firstName,
    });
  }

  return template(HOMEOWNER_ONBOARDING_STRINGS['self-serve-modal.great-fit'], {
    firstName: user?.firstName,
  });
}
