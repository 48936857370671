import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { BREAKPOINTS_WIDTHS, useWindowSize, PATHS } from '@belong/common';
import { Spinner, Switch, Text } from '@belong/ui';
import { getUnitsFromProperties } from 'accounts/utils/units';
import type { MaintenanceRecurrenceModel, UnitBasicInfoModel } from 'api/models';
import clsx from 'clsx';
import { useNotes } from 'common/hooks/useNotes';
import { DropdownFinalFormAdapter } from 'components/Dropdown/Dropdown';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { NewMediaUploadAdapter, MEDIA_LABEL } from 'components/NewMediaUploader/NewMediaUploader';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import Tooltip from 'components/Tooltip/Tooltip';
import { isEqual } from 'es-toolkit/compat';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { RecurringProServiceCard } from 'maintenance/components/recurring-pro-service-card/recurring-pro-service-card';
import { RecurringProServiceStatusTag } from 'maintenance/components/recurring-pro-service-status-tag/recurring-pro-service-status-tag';
import { ResidentCommunicationsBanner } from 'maintenance/components/resident-communications-banner/resident-communications-banner';
import { URL_QUERY_PARAMS } from 'maintenance/constants';
import { STRINGS } from 'maintenance/strings';
import type { RecurringProServiceFormSchema, RecurringProServicePreset } from 'maintenance/types';
import { getRecurringTasksByUnit, homeOwnerTasks, residentTasks } from 'maintenance/utils';
import HomeownerAccountProperty from 'models/homeownerAccounts/HomeownerAccountProperty';
import { fetchRecurringTasks } from 'store/redux/maintenance/actions';
import { selectRecurringTasks } from 'store/redux/maintenance/selectors';
import {
  cancelRecurringProService,
  createRecurringProService,
  updateRecurringProService,
} from 'store/redux/pros/actions';
import styles from './recurring-pro-service-form.module.css';

type Props = {
  isHomeOwnerView: boolean;
  preset: RecurringProServicePreset;
  properties: HomeownerAccountProperty[];
  unit?: UnitBasicInfoModel;
};

export function RecurringProServiceForm({ isHomeOwnerView, preset, properties, unit }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedHome, setSelectedHome] = useState(null);
  const [isOn, setIsOn] = useState(false);
  const [isSwitchDisabled, setIsSwitchDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { search } = useLocation();
  const recurringTasks = useSelector<unknown, MaintenanceRecurrenceModel[]>(selectRecurringTasks);
  const { width } = useWindowSize();
  const [isSwitchTouched, setIsSwitchTouched] = useState(false);

  const externalUnitId = new URLSearchParams(search).get('unitId');
  const units = getUnitsFromProperties(properties);

  const recurringTasksByUnit = getRecurringTasksByUnit(recurringTasks);
  const selectedTask = recurringTasksByUnit?.[selectedHome]?.find(
    (recurringTask) => recurringTask.recurringTask === preset.recurringTask
  );
  const recurringId = useMemo(() => (selectedTask ? [selectedTask.id] : null), [selectedTask]);
  const { notes } = useNotes({
    sourceIds: recurringId,
    sourceType: 'MaintenanceRecurrence',
  });

  const isMobile = width <= BREAKPOINTS_WIDTHS.MD;

  const dropdownStyles = isMobile
    ? {}
    : {
        width: 'max-content',
        right: 0,
        maxWidth: 'fit-content',
      };

  const isActiveByLease =
    ['PoolMaintenance', 'Landscaping'].includes(preset.recurringTask) &&
    isOn &&
    (externalUnitId === selectedHome || selectedHome || isSwitchDisabled);

  const recurringTaskImages = isHomeOwnerView ? homeOwnerTasks : residentTasks;
  const currentRecurringTask = Object.values(recurringTaskImages)
    .flat()
    .find((homeOwnerTask) => homeOwnerTask.key === preset.recurringTask);

  const image = currentRecurringTask?.image;

  const availableUnitsForCurrentRecurringTask = units.filter((unitItem) => {
    const unitRecurringTasks = recurringTasksByUnit[unitItem.basicInfo.unitId];

    const currentRecurringTaskForUnit = unitRecurringTasks?.find(
      (unitRecurringTask) => unitRecurringTask.recurringTask === preset.recurringTask
    );

    const isCurrentRecurringTaskActive =
      typeof currentRecurringTaskForUnit !== 'undefined'
        ? !currentRecurringTaskForUnit.isCancelled &&
          !['Cancelled', 'Completed', 'TurnedOff'].includes(currentRecurringTaskForUnit.recurringServiceStatus)
        : false;

    if (isCurrentRecurringTaskActive && unitItem.property.basicInfo.propertyType === 'MultiUnit') {
      return true;
    }

    return currentRecurringTask?.multiUnit || unitItem.property.basicInfo.propertyType === 'SingleFamily';
  });

  const homes = availableUnitsForCurrentRecurringTask
    .map((unitItem) => {
      const formattedAddress = unitItem.basicInfo.unitNumber
        ? `${unitItem.property.address.streetAddress}, ${STRINGS.unit} ${unitItem.basicInfo.unitNumber}`
        : unitItem.property.address.streetAddress;

      const unitRecurringTasks = recurringTasksByUnit[unitItem.basicInfo.unitId];

      const currentRecurringTaskForUnit = unitRecurringTasks?.find(
        (unitRecurringTask) => unitRecurringTask.recurringTask === preset.recurringTask
      );

      const isCurrentRecurringTaskActive =
        typeof currentRecurringTaskForUnit !== 'undefined'
          ? !currentRecurringTaskForUnit.isCancelled &&
            !['Cancelled', 'Completed', 'TurnedOff'].includes(currentRecurringTaskForUnit.recurringServiceStatus)
          : false;

      return {
        enabled: isCurrentRecurringTaskActive,
        key: unitItem.basicInfo.unitId,
        value: formattedAddress,
        streetAddress: unitItem.property.address.streetAddress,
        content: (
          <div className="pl-xs pr-lg py-xs flex items-center justify-start">
            <div className="mr-sm">
              <RecurringProServiceStatusTag status={isCurrentRecurringTaskActive ? 'on' : 'off'} />
            </div>
            <Text as="span">{formattedAddress}</Text>
          </div>
        ),
      };
    })
    .sort((firstHome, secondHome) => Number(firstHome.enabled) - Number(secondHome.enabled))
    .sort((firstHome, secondHome) => {
      if (firstHome.enabled !== secondHome.enabled) {
        return 0;
      }

      return firstHome.streetAddress.localeCompare(secondHome.streetAddress, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });

  const getActiveLeaseUnitRecurringTaskStatus = () => {
    if (!isHomeOwnerView) {
      const leaseUnitRecurringTasks = recurringTasksByUnit[unit.unitId];

      const currentRecurringTaskForLeaseUnit = leaseUnitRecurringTasks?.find(
        (unitRecurringTask) => unitRecurringTask.recurringTask === preset.recurringTask
      );

      const isCurrentRecurringTaskActive =
        typeof currentRecurringTaskForLeaseUnit !== 'undefined'
          ? !currentRecurringTaskForLeaseUnit.isCancelled &&
            !['Cancelled', 'Completed', 'TurnedOff'].includes(currentRecurringTaskForLeaseUnit.recurringServiceStatus)
          : false;

      return isCurrentRecurringTaskActive ? 'on' : 'off';
    }

    return 'off';
  };

  const getInitialValues = () => {
    const firstAvailableHome = homes.find((home) => !home.enabled);
    let home = null;

    if (isHomeOwnerView) {
      if (externalUnitId) {
        home = externalUnitId;
      } else if (firstAvailableHome?.key) {
        home = firstAvailableHome?.key;
      } else if (selectedHome) {
        home = selectedHome;
      } else if (homes.length) {
        home = homes[0]?.key;
      }
    } else {
      home = unit.unitId;
    }

    let frequency = preset.frequencies.find((frequencyItem) => frequencyItem.recommended)?.value;

    if (home && Object.keys(recurringTasksByUnit).length) {
      frequency =
        recurringTasksByUnit?.[home]?.find(
          (recurringTask) =>
            recurringTask.recurringTask === preset.recurringTask &&
            !['Cancelled', 'Completed', 'TurnedOff'].includes(recurringTask.recurringServiceStatus)
        )?.repeatFrequencyDays || frequency;
    }

    const notesObj: any = {};

    if (notes?.length) {
      notesObj.description = notes[0].notes[0].note?.body;
      notesObj.media = { availableMedia: notes[0].notes[0].media };
    }

    return {
      home,
      frequency,
      ...notesObj,
    };
  };

  const handleSwitchChange = () => setIsOn((prevIsOn) => !prevIsOn);

  async function handleFormSubmit(values: RecurringProServiceFormSchema) {
    try {
      const { description, frequency, home, media } = values;

      setIsSubmitting(true);

      const urlSearchParams = new URLSearchParams();

      urlSearchParams.append(URL_QUERY_PARAMS.TOAST_VISIBLE, 'true');
      urlSearchParams.append(URL_QUERY_PARAMS.SUCCESS, 'true');

      if (isOn) {
        const initialFrequency = recurringTasksByUnit?.[home]?.find(
          (recurringTask) =>
            recurringTask.recurringTask === preset.recurringTask &&
            !['Cancelled', 'Completed', 'TurnedOff'].includes(recurringTask.recurringServiceStatus)
        )?.repeatFrequencyDays;
        const initialNoteId = notes?.length ? notes[0].notes[0].note?.id : undefined;

        if (initialFrequency) {
          await dispatch(
            updateRecurringProService({
              unitId: home,
              recurringTask: preset.recurringTask,
              frequency,
              description,
              media,
              noteId: initialNoteId,
            })
          );

          urlSearchParams.append(URL_QUERY_PARAMS.UPDATE_FREQUENCY, 'true');
        } else {
          await dispatch(
            createRecurringProService({
              homeUniqueId: home,
              recurringTask: preset.recurringTask,
              frequency,
              sourceType: 'Web',
              description,
              media,
            })
          );

          urlSearchParams.append(URL_QUERY_PARAMS.CREATION, 'true');
        }
      } else {
        await dispatch(
          cancelRecurringProService({
            recurringTask: preset.recurringTask,
            unitId: home,
          })
        );

        urlSearchParams.append(URL_QUERY_PARAMS.TURN_OFF, 'true');
      }

      push({
        pathname: isHomeOwnerView ? PATHS.HOMEOWNER_ACCOUNT_MAINTENANCE : PATHS.RESIDENTS_ACCOUNT_MAINTENANCE,
        search: urlSearchParams.toString(),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    async function fetchRecurring() {
      setIsLoading(true);

      await dispatch(fetchRecurringTasks());

      setIsLoading(false);
    }

    fetchRecurring();
  }, [dispatch]);

  useEffect(() => {
    if (!isHomeOwnerView) {
      const leaseUnitRecurringTasks = recurringTasksByUnit[unit.unitId];

      if (leaseUnitRecurringTasks && preset) {
        const currentRecurringTaskForLeaseUnit = leaseUnitRecurringTasks?.find(
          (unitRecurringTask) => unitRecurringTask.recurringTask === preset.recurringTask
        );

        const isCurrentRecurringTaskActive =
          typeof currentRecurringTaskForLeaseUnit !== 'undefined'
            ? !currentRecurringTaskForLeaseUnit.isCancelled &&
              !['Cancelled', 'Completed', 'TurnedOff'].includes(currentRecurringTaskForLeaseUnit.recurringServiceStatus)
            : false;

        if (!isSwitchTouched) {
          setIsOn(isCurrentRecurringTaskActive);
          setIsSwitchTouched(true);
        }
      }
    }
  }, [isHomeOwnerView, unit, recurringTasksByUnit, preset, isSwitchTouched]);

  useEffect(() => {
    if (isHomeOwnerView && selectedHome && homes?.length) {
      const isRecurringServiceActive = homes.find((homeItem) => homeItem.key === selectedHome)?.enabled;

      if (!isSwitchTouched && isRecurringServiceActive) {
        setIsOn(isRecurringServiceActive);
        setIsSwitchTouched(true);
      }
    }
  }, [isHomeOwnerView, selectedHome, homes, isSwitchTouched, selectedHome]);

  useEffect(() => {
    if (homes && !selectedHome && !isLoading) {
      if (isHomeOwnerView && externalUnitId) {
        setSelectedHome(externalUnitId);
      } else {
        const firstHome = homes.length ? homes[0] : null;
        const home = isHomeOwnerView ? firstHome?.key || null : unit.unitId;

        if (home) {
          setSelectedHome(home);
        }
      }
    }
  }, [homes, selectedHome, isLoading, externalUnitId, isHomeOwnerView]);

  if (isLoading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <FormLayout>
      {isSubmitting && <Spinner />}
      <Form
        onSubmit={handleFormSubmit}
        initialValues={getInitialValues()}
        getFormBottomBar={({ form }, nextButtonProps) => {
          const { home, frequency, description, media } = form.getState().values;

          const initialFrequency = recurringTasksByUnit?.[home]?.find(
            (recurringTask) => recurringTask.recurringTask === preset.recurringTask
          )?.repeatFrequencyDays;
          const initialNote = notes?.length ? notes[0].notes[0].note?.body : undefined;
          const initialMedia = notes?.length ? notes[0].notes[0].media.map((img) => img.name) : undefined;
          const currentMedia = media?.mediaToDisplay?.map((img) => img.name);

          const isFrequencyPristine = initialFrequency ? initialFrequency !== frequency : false;
          const isNotePristine = initialNote !== description;
          const isMediaPristine = !isEqual(initialMedia, currentMedia);

          const isFormPristine = isFrequencyPristine || isNotePristine || isMediaPristine;

          const isRecurringServiceAlreadyEnabledForSelectedHome = isHomeOwnerView
            ? homes.find((homeItem) => homeItem.key === home)?.enabled
            : getActiveLeaseUnitRecurringTaskStatus() === 'on';

          const disabled =
            !home ||
            (isRecurringServiceAlreadyEnabledForSelectedHome && isOn && !isFormPristine) ||
            (!isRecurringServiceAlreadyEnabledForSelectedHome && !isOn);

          return (
            <div className="flex items-center justify-center mb-3xl">
              <FormLayout.Button
                buttonClassNames="w-button-fluid sm:w-button-default h-button"
                ctaProps={{
                  label: 'Save',
                  disabled,
                }}
                nextButtonWrapperProps={nextButtonProps}
              />
            </div>
          );
        }}
        getForm={({ form, handleSubmit, values }) => {
          const { home } = values;

          return (
            <form className="flex flex-col" onSubmit={handleSubmit}>
              {isHomeOwnerView && (
                <>
                  <div className="mb-sm">
                    <Text as="h2" fontWeight="semibold" variant="h3">
                      {STRINGS['which-home']}
                    </Text>
                  </div>
                  <div className="mb-xl">
                    <Field
                      component={DropdownFinalFormAdapter}
                      itemClassname={styles.homes}
                      items={homes}
                      name="home"
                      disabled={homes?.length === 1}
                      placeholder={STRINGS['select-home']}
                      withMarginTop={false}
                      downshiftMenuStyle={dropdownStyles}
                      onChangeCustom={(newHome) => {
                        if (homes) {
                          const isRecurringServiceActive = homes.find((homeItem) => homeItem.key === newHome)?.enabled;

                          setIsOn(isRecurringServiceActive);
                          setIsSwitchDisabled(isRecurringServiceActive);
                          setSelectedHome(newHome);

                          const initialFrequency = recurringTasksByUnit?.[newHome]?.find(
                            (recurringTask) =>
                              recurringTask.recurringTask === preset.recurringTask &&
                              !['Cancelled', 'Completed', 'TurnedOff'].includes(recurringTask.recurringServiceStatus)
                          )?.repeatFrequencyDays;

                          if (isRecurringServiceActive && initialFrequency) {
                            form.change('frequency', initialFrequency);
                          }
                        }
                      }}
                    />
                  </div>
                </>
              )}
              <div className={clsx('mb-xl', isHomeOwnerView && 'mt-lg')}>
                <RecurringProServiceCard
                  description={preset.description}
                  imageSrc={image}
                  isHomeownerView={isHomeOwnerView}
                  name={preset.recurringTask}
                  price={preset.startCostFrom}
                  status={getActiveLeaseUnitRecurringTaskStatus()}
                />
              </div>
              <div className="mb-2xl flex justify-center items-center">
                <Switch
                  checked={isOn}
                  disabled={(isHomeOwnerView && !home) || isActiveByLease}
                  id="on"
                  onChange={(checked: boolean) => {
                    const initialFrequency = recurringTasksByUnit?.[home]?.find(
                      (recurringTask) =>
                        recurringTask.recurringTask === preset.recurringTask &&
                        !['Cancelled', 'Completed', 'TurnedOff'].includes(recurringTask.recurringServiceStatus)
                    )?.repeatFrequencyDays;

                    if (checked && !initialFrequency) {
                      const recommended = preset.frequencies.find((frequencyItem) => frequencyItem.recommended)?.value;

                      form.change('frequency', recommended);
                    }

                    handleSwitchChange();
                  }}
                  size="large"
                >
                  <Switch.Left as="label">{STRINGS.off}</Switch.Left>
                  <Switch.Right as="label">{STRINGS.on}</Switch.Right>
                </Switch>
                {isActiveByLease && (
                  <div className="ml-xs">
                    <Tooltip
                      contentStyle={{
                        ...(!isMobile && {
                          width: '365px',
                        }),
                      }}
                    >
                      {STRINGS['disabled-services']}
                    </Tooltip>
                  </div>
                )}
              </div>
              {isHomeOwnerView && (
                <div className="mb-2xl">
                  <ResidentCommunicationsBanner />
                </div>
              )}
              {isOn && (
                <>
                  <div className="mb-lg">
                    <Text fontWeight="semibold" variant="h3">
                      {STRINGS['frequency-question']}
                    </Text>
                  </div>
                  <div className="mb-2xl">
                    <Field
                      name="frequency"
                      component={SelectorFinalFormAdapter}
                      buttonClassName={isMobile ? 'justify-center' : ''}
                      buttons={preset.frequencies
                        .sort((a, b) => (a.value > b.value ? 1 : -1))
                        .map((frequency) => ({
                          label: frequency.displayName,
                          key: frequency.value,
                          ...(frequency.recommended && {
                            subLabel: (
                              <div className="absolute left-0 right-0 w-min mx-auto -top-2sm px-xs rounded-small bg-green">
                                <Text className="text-white" variant="p1">
                                  {STRINGS.recommended}
                                </Text>
                              </div>
                            ),
                          }),
                        }))}
                      type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                      withMarginTop={false}
                    />
                  </div>
                  <div className="mb-lg">
                    <Text fontWeight="semibold" variant="h3">
                      {STRINGS['extras-question']}
                    </Text>
                  </div>
                  <div className="mb-xl">
                    <Field
                      component={InputFinalFormAdapter}
                      name="description"
                      placeholder={STRINGS['unique-needs']}
                      withMarginTop={false}
                    />
                  </div>
                  <div className="mb-2xl">
                    <Field name="media" component={NewMediaUploadAdapter} mediaLabel={MEDIA_LABEL.PHOTO_OR_VIDEO} />
                  </div>
                </>
              )}
            </form>
          );
        }}
      />
    </FormLayout>
  );
}
