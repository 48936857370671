import { TRACKING_STRINGS } from 'strings/tracking.strings';
import { getApiActionTypesValues } from '../redux_utils';

export const fetchTracking = (queryParam = '') => ({
  types: getApiActionTypesValues('FETCH_TRACKING'),
  promise: ({ client }) => client.get(`/tracking?${queryParam}`),
});

export const trackEvent = (trackingData) => ({
  types: getApiActionTypesValues('TRACK_EVENT'),
  promise: ({ client }) => client.post(`/tracking`, trackingData),
});

export function trackSplashScreenViewed(userId) {
  return trackEvent({
    eventName: TRACKING_STRINGS.eventNames.splash_screen_viewed,
    metadata: { viewDate: new Date(), userId },
  });
}

export const trackSelfServeDecisionTaken = (flowId, decision) => ({
  promise: ({ client }) =>
    client.post(`/homeowners/sign-up/${flowId}/self-serve-decision`, {
      selfServeDecisionTaken: decision,
    }),
});
