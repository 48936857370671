export const EMPLOYEE_TITLES = {
  PricingAnalyst: 'Pricing Lead',
  Concierge: 'Your Member Success Lead',
  HomeownerGrowth: 'Homeowner Advisor',
  ResidentGrowth: 'Leasing Advisor',
  SalesOperation: 'Onboarding Ops',
  ImprovementsAssessor: 'Onboarding Ops',
  Pro: 'Belong Pro',
  HeadOfOperations: 'Head Of Operations',
  HeadOfExperience: 'Head Of Experience',
  SalesDevelopmentRepresentative: 'Homeowner Associate',
  HomeImprovementsSpecialist: 'Home Improvements Specialist',
  PricingTeam: 'Pricing Team',
  HomeOccupancySpecialist: 'Home Occupancy Specialist',
  BelongTeamMember: 'Belong Team Member',
};

export const LastUpdated = {
  last_updated_general: 'Last Updated {date}',
};
