import { useEffect } from 'react';
import { useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { AddressAutocompleteFinalFormAdapter } from 'components/AddressAutocomplete/AddressAutocomplete';
import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
import Field from 'components/Field/Field';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import { Text } from 'design-system';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { NextHomePlans, MoveoutOwningStatus, MoveoutRentingStatusOptions } from 'models/enums';
import PropTypes from 'prop-types';
import { fetchAvailableCitiesAndStates } from 'store/redux/listing-page-for-homes/actions';
import { selectAvailableCityStates } from 'store/redux/listing-page-for-homes/selectors';
import { formatString } from 'strings';
import { RENTAL_STATUS_TEXT } from 'strings/rental-status';
import { required } from 'utils/validation';

const moveoutPlanOptions = [
  { label: NextHomePlans.Rent, key: NextHomePlans.Rent },
  { label: NextHomePlans.Own, key: NextHomePlans.Own },
];

const moveoutOwningStatusOptions = [
  { label: 'Looking', key: MoveoutOwningStatus.Looking },
  { label: 'Made <span>An Offer</span>', key: MoveoutOwningStatus.MadeAnOffer },
  { label: 'Closing', key: MoveoutOwningStatus.Closing },
  { label: 'Closed', key: MoveoutOwningStatus.Closed },
  { label: 'Renovating', key: MoveoutOwningStatus.Renovating },
  { label: 'Ready <span>To Go</span>', key: MoveoutOwningStatus.ReadyToGo },
];

const moveoutRentingStatusOptions = [
  { label: 'Looking', key: MoveoutRentingStatusOptions.Looking },
  { label: 'Applied', key: MoveoutRentingStatusOptions.Applied },
  { label: 'Signed A Lease', key: MoveoutRentingStatusOptions.SignedLease },
];

export const RentYourNextHomeFields = ({ name }) => {
  const form = useForm();
  const { values } = form.getState();
  const dispatch = useDispatch();
  const availableCitiesWithState = useSelector(selectAvailableCityStates);
  const formattedCitiesWithState = availableCitiesWithState.map((place) => `${place.city}, ${place.state}`);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchAvailableCitiesAndStates());
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();
  }, []);

  const onSelect = (_form) => (parsedAddress) => {
    if (parsedAddress) {
      _form.change(`${name}.moveoutDestination.inputValue`, `${parsedAddress.city}, ${parsedAddress.state}`);
    }
  };

  const city = values[name]?.moveoutDestination?.inputValue?.split(',')?.[0];

  return (
    <>
      <FormLayout.Section sectionTitle={RENTAL_STATUS_TEXT.nextHomeQuestion}>
        <Row>
          <div className="contents md:hidden">
            <Col xs={12}>
              <Field
                name={`${name}.moveoutPlan.inputValue`}
                component={SelectorFinalFormAdapter}
                type={SELECTOR_TYPES.SMALLTEXTBUTTONWIDTH}
                buttons={moveoutPlanOptions}
                validate={values[name]?.moveoutPlan?.checkboxValue ? undefined : required}
                fluid
                disabled={Boolean(values[name]?.moveoutPlan?.checkboxValue)}
                key={values[name]?.moveoutPlan?.checkboxValue ? `${name}.inputValue0` : `${name}.inputValue1`}
              />
              <Field
                name={`${name}.moveoutPlan.checkboxValue`}
                component={CheckboxFinalFormAdapter}
                label={RENTAL_STATUS_TEXT.nextHomeMoveoutNotSure}
                alignWithField={false}
                alignCenter
                useOnChangeFormToHandle
                onClickCustom={(value) => {
                  if (value) {
                    form.change(`${name}.moveoutPlan.inputValue`, undefined);
                  }
                }}
              />
            </Col>
          </div>
          <div className="hidden md:flex md:justify-between md:mx-2sm">
            <Field
              name={`${name}.moveoutPlan.inputValue`}
              component={SelectorFinalFormAdapter}
              type={SELECTOR_TYPES.SMALLTEXTBUTTONWIDTH}
              buttons={moveoutPlanOptions}
              validate={values[name]?.moveoutPlan?.checkboxValue ? undefined : required}
              fluid
              disabled={Boolean(values[name]?.moveoutPlan?.checkboxValue)}
              key={values[name]?.moveoutPlan?.checkboxValue ? `${name}.inputValue0` : `${name}.inputValue1`}
            />
            <div className="md:pt-2sm">
              <Field
                name={`${name}.moveoutPlan.checkboxValue`}
                component={CheckboxFinalFormAdapter}
                label={RENTAL_STATUS_TEXT.nextHomeMoveoutNotSure}
                alignWithField={false}
                alignCenter
                useOnChangeFormToHandle
                onClickCustom={(value) => {
                  if (value) {
                    form.change(`${name}.moveoutPlan.inputValue`, undefined);
                  }
                }}
              />
            </div>
          </div>
          <div className="contents md:hidden mt-sm">
            <Col xs={12}>
              <Field
                name={`${name}.moveoutDestination.inputValue`}
                component={AddressAutocompleteFinalFormAdapter}
                validate={values[name]?.moveoutDestination?.checkboxValue ? undefined : required}
                placeholder={RENTAL_STATUS_TEXT.nextHomeMoveoutDestinationPlaceholder}
                onSelect={onSelect(form)}
                disabled={Boolean(values[name]?.moveoutDestination?.checkboxValue)}
                searchOptions={{ types: ['(cities)'] }}
                key={values[name]?.moveoutDestination?.checkboxValue ? `${name}.inputValue0` : `${name}.inputValue1`}
              />
              <div className="pt-sm">
                <Field
                  name={`${name}.moveoutDestination.checkboxValue`}
                  component={CheckboxFinalFormAdapter}
                  label={RENTAL_STATUS_TEXT.nextHomeMoveoutDestinationNotSure}
                  alignWithField={false}
                  alignCenter
                  useOnChangeFormToHandle
                  onClickCustom={(value) => {
                    if (value) {
                      form.change(`${name}.moveoutDestination.inputValue`, undefined);
                    }
                  }}
                />
              </div>
            </Col>
          </div>
          <div className="hidden md:flex md:justify-between md:gap-xl md:mt-sm  md:mx-2sm">
            <Field
              name={`${name}.moveoutDestination.inputValue`}
              component={AddressAutocompleteFinalFormAdapter}
              fieldWrapperClassName="w-full"
              validate={values[name]?.moveoutDestination?.checkboxValue ? undefined : required}
              placeholder={RENTAL_STATUS_TEXT.nextHomeMoveoutDestinationPlaceholder}
              onSelect={onSelect(form)}
              disabled={Boolean(values[name]?.moveoutDestination?.checkboxValue)}
              searchOptions={{ types: ['(cities)'] }}
              key={values[name]?.moveoutDestination?.checkboxValue ? `${name}.inputValue0` : `${name}.inputValue1`}
            />
            <div className="md:pt-xl flex-shrink-0">
              <Field
                name={`${name}.moveoutDestination.checkboxValue`}
                component={CheckboxFinalFormAdapter}
                label={RENTAL_STATUS_TEXT.nextHomeMoveoutDestinationNotSure}
                alignWithField={false}
                alignCenter
                useOnChangeFormToHandle
                onClickCustom={(value) => {
                  if (value) {
                    form.change(`${name}.moveoutDestination.inputValue`, undefined);
                  }
                }}
              />
            </div>
          </div>
        </Row>
      </FormLayout.Section>
      {!!values[name]?.moveoutPlan?.inputValue && (
        <FormLayout.Section sectionTitle={RENTAL_STATUS_TEXT.nextHomeProcessOwningStatusQuestion}>
          {values[name]?.moveoutPlan?.inputValue === NextHomePlans.Own && (
            <Row>
              <Col xs={12}>
                <Field
                  name={`${name}.moveoutOwningStatus`}
                  fluid
                  component={SelectorFinalFormAdapter}
                  validate={required}
                  buttons={moveoutOwningStatusOptions}
                  type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                />
              </Col>
            </Row>
          )}
          {values[name]?.moveoutPlan?.inputValue === NextHomePlans.Rent && (
            <>
              <Row>
                <Col xs={12}>
                  <Field
                    name={`${name}.moveoutRentingStatus`}
                    component={SelectorFinalFormAdapter}
                    validate={required}
                    buttons={moveoutRentingStatusOptions}
                    type={SELECTOR_TYPES.SMALLTEXTBUTTON}
                  />
                </Col>
              </Row>
              {formattedCitiesWithState.includes(values[name]?.moveoutDestination?.inputValue) &&
                values[name]?.moveoutRentingStatus === MoveoutRentingStatusOptions.Looking && (
                  <GreenInfoBox>
                    <Text>{formatString(RENTAL_STATUS_TEXT.nextHomeMoveoutRentingStatusNote, { city })}</Text>
                  </GreenInfoBox>
                )}
            </>
          )}
        </FormLayout.Section>
      )}
    </>
  );
};

RentYourNextHomeFields.propTypes = {
  name: PropTypes.string.isRequired,
};
