import {
  SUPPORTED_HOME_FEATURE_NAMES,
  HOME_FEATURE_CATEGORIES,
  HOME_FEATURES_LIST,
} from 'accounts/constants/home-features';
import { isNil } from 'es-toolkit/compat';
import { AcFeatureTypes, ParkingTypes } from 'models/enums';

export function getHomeFeaturesList(isInspectionCompleted) {
  const featuresList = [...HOME_FEATURES_LIST];

  if (isInspectionCompleted) {
    return featuresList.map((feature) => ({ ...feature, disabled: true }));
  }

  return featuresList;
}

export function getFeatureAttributes(feature: { name: string; attributes: unknown; uniqueId: string; type: string }) {
  const featureProps: {
    name: string;
    isAvailable: boolean;
    attributes: {
      isCentralAirCondition?: boolean;
      spots?: [{ hasAssignedNumber: boolean; spotNumber: string }];
    };
    uniqueId?: string;
    category?: string;
    isAppliance?: boolean;
  } = {
    name: feature.name,
    isAvailable: true,
    attributes: feature.attributes || {},
  };

  if (feature.uniqueId) {
    featureProps.uniqueId = feature.uniqueId;
  }

  if (SUPPORTED_HOME_FEATURE_NAMES.Parking.name === feature.name) {
    featureProps.isAppliance = false;
    featureProps.category = HOME_FEATURE_CATEGORIES.Parking;
    featureProps.name = feature.type;

    switch (feature.type) {
      case ParkingTypes.DrivewayParking:
        delete featureProps.attributes.spots;
        break;

      case ParkingTypes.OffStreetParking:
      case ParkingTypes.OnStreetParking:
        delete featureProps.attributes;
        break;

      default:
        if (featureProps.attributes?.spots?.length) {
          featureProps.attributes.spots.forEach((s) => {
            if (!isNil(s)) {
              if (!s.hasAssignedNumber) {
                delete s.spotNumber;
              }
              delete s.hasAssignedNumber;
            }
          });
        }
        break;
    }
  }

  if (SUPPORTED_HOME_FEATURE_NAMES.Storage.name === feature.name) {
    featureProps.isAppliance = false;
    featureProps.category = HOME_FEATURE_CATEGORIES.Storage;
  }

  if (SUPPORTED_HOME_FEATURE_NAMES.Heat.name === feature.name) {
    featureProps.isAppliance = true;
    featureProps.category = HOME_FEATURE_CATEGORIES['Climate Control'];
  }

  if (SUPPORTED_HOME_FEATURE_NAMES.Ac.name === feature.name) {
    featureProps.isAppliance = true;
    featureProps.category = HOME_FEATURE_CATEGORIES['Climate Control'];

    if (feature.type === AcFeatureTypes.CentralAc) {
      featureProps.attributes.isCentralAirCondition = true;
    } else {
      featureProps.attributes.isCentralAirCondition = false;
    }
  }

  if (SUPPORTED_HOME_FEATURE_NAMES.Laundry.name === feature.name) {
    featureProps.isAppliance = true;
    featureProps.category = HOME_FEATURE_CATEGORIES.Laundry;
  }

  if ([SUPPORTED_HOME_FEATURE_NAMES.Pool.name, SUPPORTED_HOME_FEATURE_NAMES.Jacuzzi.name].includes(feature.name)) {
    featureProps.isAppliance = true;
    featureProps.category = HOME_FEATURE_CATEGORIES.Pool;
  }

  if (SUPPORTED_HOME_FEATURE_NAMES.SecuritySystem.name === feature.name) {
    featureProps.isAppliance = true;
    featureProps.category = HOME_FEATURE_CATEGORIES['Safety & Security'];
  }

  if (
    [
      SUPPORTED_HOME_FEATURE_NAMES['Trees/ Landscaping/ Garden'].name,
      SUPPORTED_HOME_FEATURE_NAMES.AutomaticSprinklers.name,
    ].includes(feature.name)
  ) {
    featureProps.isAppliance = false;
    featureProps.category = HOME_FEATURE_CATEGORIES.Yard;
  }

  return { ...featureProps };
}

export function getFeatureFormObject(feature) {
  const featureObject = {
    selected: !!feature.isAvailable,
    ...feature,
  };

  if (feature.name === SUPPORTED_HOME_FEATURE_NAMES.Ac.name) {
    featureObject.type = feature.attributes?.isCentralAirCondition
      ? AcFeatureTypes.CentralAc
      : AcFeatureTypes.WindowUnits;
  }

  if (Object.values(ParkingTypes).includes(feature.name) && feature.name) {
    featureObject.type = feature.name;

    if (featureObject.attributes?.spots?.length) {
      featureObject.attributes.spots.forEach((s) => {
        if (!s || !s.spotNumber) {
          s = {
            hasAssignedNumber: false,
          };
        } else if (s && s.spotNumber) {
          s.hasAssignedNumber = true;
        }
      });
    }
  }

  return { ...featureObject };
}
