import React from 'react';
import classNames from 'classnames/bind';
import { compact } from 'es-toolkit/compat';
import PropTypes from 'prop-types';
import styles from './BulletList.module.css';

const cx = classNames.bind(styles);

export default class BulletList extends React.Component {
  static propTypes = {
    listItems: PropTypes.array.isRequired,
    wrap: PropTypes.bool,
    flexWrap: PropTypes.bool,
    small: PropTypes.bool,
    render: PropTypes.func,
  };

  static defaultProps = {
    wrap: false,
    flexWrap: false,
    small: false,
    render: null,
  };

  render() {
    const { listItems, wrap, small, flexWrap, render } = this.props;
    const cleanListItems = compact(listItems);

    if (!cleanListItems || !cleanListItems.length) {
      return null;
    }

    const lastItemIndex = cleanListItems.length - 1;
    const listItemElements = cleanListItems.map((listItem, index) => (
      <span key={index}>
        {render ? render(listItem) : listItem}
        {index !== lastItemIndex && <span className={cx('bullet')}>&bull;</span>}
      </span>
    ));
    return <span className={cx('bullet-list', { wrap, small, flexWrap })}>{listItemElements}</span>;
  }
}
