import React, { useContext, useState, useEffect } from 'react';
import Measure from 'react-measure';
import classNames from 'classnames/bind';
import Button, { BUTTON_TYPES, BUTTON_SIZES } from 'components/Button/Button';
import Logo from 'components/Logo/Logo';
import TextBubbleWithUserProfile from 'components/TextBubbleWithUserProfile/TextBubbleWithUserProfile';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { last } from 'es-toolkit/compat';
import { Col, Grid, Row } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
import { THEME_CONSTS } from 'themes/themes';
import styles from './ContentLayout.module.css';

const cx = classNames.bind(styles);

const propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  showCityBackground: PropTypes.bool,
  employee: PropTypes.object.isRequired,
  actions: PropTypes.array,
  topFiller: PropTypes.node,
  headerBorder: PropTypes.bool,
  hasCustomFixedPageHeader: PropTypes.bool,
  noMinHeight: PropTypes.bool,
  noOverlay: PropTypes.bool,
};

const defaultProps = {
  showCityBackground: false,
  actions: [],
  topFiller: null,
  headerBorder: true,
  noMinHeight: false,
  noOverlay: false,
  hasCustomFixedPageHeader: false,
};

const ContentLayoutContext = React.createContext();

export const ContentLayout = ({
  title,
  children,
  showCityBackground,
  employee,
  actions,
  headerBorder,
  topFiller,
  noMinHeight,
  noOverlay,
  hasCustomFixedPageHeader,
}) => {
  const [ctaProps, setCtaProps] = useState(null);

  return (
    <ContentLayoutContext.Provider
      value={{
        setCtaProps,
        ctaProps,
      }}
    >
      {ctaProps && (
        <ContentLayout.FixedPageHeader
          headerBorder={headerBorder}
          actions={[
            ...actions,
            <div key={0} className={cx('header-cta-container')}>
              <Button
                disabled={ctaProps.disabled}
                buttonType={BUTTON_TYPES.DEFAULT}
                size={BUTTON_SIZES.FIT}
                onClick={ctaProps.onClick}
              >
                {ctaProps.children}
              </Button>
            </div>,
          ]}
        />
      )}

      <Grid>
        <Row className={cx('flex-row', { hasCustomFixedPageHeader })}>
          <Col lg={2} md={1} smHidden xsHidden className={cx('col-z-index')}>
            {!noOverlay && <div className={cx('overlay', 'left')} />}
          </Col>
          <Col className={cx('layout', { noMinHeight })} lg={8} md={10} xs={12}>
            {topFiller && topFiller}
            <div className={cx('title')}>
              <TextBubbleWithUserProfile text={title} employee={employee} />
            </div>
            {children}
            {!noMinHeight && <Space value={SPACE_TYPES.XXXXXL} />}
          </Col>
          <Col lg={2} md={1} smHidden xsHidden className={cx('col-z-index')}>
            {!noOverlay && <div className={cx('overlay', 'right')} />}
          </Col>
        </Row>
      </Grid>
      {showCityBackground && (
        <div className={cx('fixed-background')}>
          <img src="/city_images/city_side_fold_full.svg" alt="city" />
        </div>
      )}
    </ContentLayoutContext.Provider>
  );
};

ContentLayout.CtaButton = ({ children, onClick, disabled, ...rest }) => {
  const contentLayoutContext = useContext(ContentLayoutContext);
  const [buttonBottom, setButtonBottom] = useState(null);

  useEffect(() => {
    if (buttonBottom) {
      contentLayoutContext.setCtaProps({
        disabled,
        children,
        onClick,
        buttonBottom,
      });
    }
    // Don't like checking on all this deps. But only way to get updated values inside the onClick.
  }, [children, disabled, buttonBottom, onClick]);

  return (
    <Measure
      bounds
      onResize={({ bounds }) => {
        // bounds.bottom is relative to scrollY, we want relative to scrollY = 0
        setButtonBottom(window.scrollY + bounds.bottom);
      }}
    >
      {({ measureRef }) => (
        <div ref={measureRef} className={cx('cta-container')}>
          <Button
            buttonType={BUTTON_TYPES.DEFAULT}
            size={BUTTON_SIZES.FLUID_LONG_RESPONSIVE}
            onClick={onClick}
            disabled={disabled}
            {...rest}
          >
            {children}
          </Button>
        </div>
      )}
    </Measure>
  );
};

ContentLayout.CtaButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ContentLayout.CtaButton.defaultProps = {
  disabled: false,
};

ContentLayout.propTypes = propTypes;
ContentLayout.defaultProps = defaultProps;

const fixedHeaderHeight = 60;
ContentLayout.FixedPageHeader = ({ actions, headerBorder }) => {
  const contentLayoutContext = useContext(ContentLayoutContext);
  const animationBoundary = contentLayoutContext?.ctaProps?.buttonBottom;
  const [visible, setVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > animationBoundary - fixedHeaderHeight) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [animationBoundary]);

  return (
    <>
      <div
        role="dialog"
        aria-label="Information Navigation"
        className={cx('header-background', {
          hide: !visible,
          show: !!visible,
          hideBorder: !headerBorder,
        })}
      />
      <div
        className={cx('fixed', {
          hide: !visible,
        })}
      >
        <Grid>
          <div className={cx('fixed-header-wrapper')}>
            <div className={cx('logo-wrappers')}>
              <Logo theme={THEME_CONSTS.DARK} />
            </div>
            <div className={cx('desktop-actions-wrapper')}>
              {actions.map((action, index) => (
                <div className={cx('action')} key={index}>
                  {action}
                </div>
              ))}
            </div>
            {/* Show only the last action, since there is no generic way to handle multiple
            actions for mobile */}
            <div className={cx('mobile-actions-wrapper')}>{last(actions)}</div>
          </div>
        </Grid>
      </div>
    </>
  );
};

ContentLayout.FixedPageHeader.propTypes = {
  actions: PropTypes.array.isRequired,
  headerBorder: PropTypes.bool,
};

ContentLayout.FixedPageHeader.defaultProps = {
  headerBorder: true,
};
