import { useSelector } from 'react-redux';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import ResidentTileList from 'containercomponents/ResidentTileList/ResidentTileList';
import ResidentTileListWithStatus from 'containercomponents/ResidentTileListWithStatus/ResidentTileListWithStatus';
import { differenceInCalendarYears } from 'date-fns';
import { flatten } from 'es-toolkit/compat';
import { legacyParse } from 'forkedlibraries/date-fns-upgrade';
import { ResidentType } from 'models/enums';
import { selectUser } from 'store/redux/user/selectors';

const getPetIcon = (type) => {
  if (type === 'Dog') {
    return GENERAL_ICONS.DOG_PROFILE;
  } else if (type === 'Cat') {
    return GENERAL_ICONS.CAT_PROFILE;
  }

  return GENERAL_ICONS.OTHER_PET_PROFILE;
};

type ResidentListContainerProps = {
  residents: Record<string, unknown>;
  withStatus?: boolean;
  // TODO: add orientation to ResidentTileList if needed (now is only implemented for ResidentTileListWithStatus)
  orientation?: 'horizontal' | 'vertical';
};

const ResidentListContainer = ({
  residents,
  withStatus = false,
  orientation = 'horizontal',
}: ResidentListContainerProps) => {
  const currentUser = useSelector(selectUser);
  const { residents: residentsList = [], kids = [], pets = [] } = residents || {};
  const residentsProps = residentsList?.map((resident) => {
    const { basicInfo, leaseInfo } = resident;
    const { userInfo } = basicInfo;

    let residentSubtitle;
    switch (leaseInfo?.residentType) {
      case ResidentType.Primary:
        residentSubtitle = 'Primary Resident';
        break;
      case ResidentType.CoSigner:
        residentSubtitle = 'Co-signer';
        break;
      case ResidentType.Roommate:
      default:
        residentSubtitle = 'Resident';
        break;
    }
    return {
      profileProps: {
        imageUrl: userInfo.profileImageUrl,
        title: userInfo.userId === currentUser.userId ? 'Me' : `${userInfo.firstName} ${userInfo.lastName}`,
        subtitles: [residentSubtitle],
        userId: userInfo.userId,
        signatureStatus: leaseInfo?.signatureStatus,
      },
    };
  });

  const kidProps = kids?.map((kid) => {
    const birthdate = new Date(kid.dateOfBirth);
    const childAgeInYears = differenceInCalendarYears(legacyParse(Date.now()), legacyParse(birthdate));
    return {
      profileProps: {
        title: kid.name,
        subtitles: [childAgeInYears > 0 ? `${childAgeInYears} Year${childAgeInYears === 1 ? '' : 's'} Old` : 'Baby'],
        customEmptyProfileIcon: GENERAL_ICONS.KIDS_PROFILE,
        // signatureStatus: 'Signature Not Required',
      },
    };
  });

  const petProps = pets?.map((pet) => ({
    profileProps: {
      title: pet.name,
      subtitles: [`${pet.subType || ''}`],
      customEmptyProfileIcon: getPetIcon(pet.petType),
      // signatureStatus: 'Signature Not Required',
    },
  }));

  const getInfo = [...residentsProps, ...kidProps, ...petProps];
  const newArrayOfResidents = [];
  getInfo.forEach((value, index) => {
    const number = (index - 1) / 2;

    if (!newArrayOfResidents[Math.round(number)]) {
      newArrayOfResidents[Math.round(number)] = [];
    }

    newArrayOfResidents[Math.round(number)].push(value);
  });

  return withStatus ? (
    // Will never understand why we gave residents the shape we are giving it right now
    // But will flatten out since this component is be new.
    <ResidentTileListWithStatus residents={flatten(newArrayOfResidents)} orientation={orientation} />
  ) : (
    <ResidentTileList residents={newArrayOfResidents} />
  );
};

export default ResidentListContainer;
