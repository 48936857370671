import { Button } from '@belong/ui';
import { useFullManagementHomes } from 'hooks/useFullManagementHomes';

type AddMaintenanceButtonProps = {
  label: string;
  onPress: () => void;
};

export const AddMaintenanceButton = ({ label, onPress }: AddMaintenanceButtonProps) => {
  const activeFullManagementHomes = useFullManagementHomes();
  const hasActiveFullManagementHomes = activeFullManagementHomes.length > 0;

  return (
    <div className="hidden sm:flex sm:ml-[2em] items-center justify-end">
      <Button
        buttonType="primary"
        size="auto"
        onClick={onPress}
        className="px-xs"
        disabled={!hasActiveFullManagementHomes}
      >
        {label}
      </Button>
    </div>
  );
};
