import { formatDateTime, formatUtcDateTimeToTimeZone, DATE_FORMATS } from '@belong/common';
import classNames from 'classnames/bind';
import Money, { MONEY_FORMAT } from 'components/Money/Money';
import { addWeeks } from 'date-fns';
import { legacyParse } from 'forkedlibraries/date-fns-upgrade';
import PropTypes from 'prop-types';
import { arrayInsertIf } from 'utils/insertIf';
import DisplayAppointment from './DisplayAppointment/DisplayAppointment';
import DisplayDefaultEvent from './DisplayDefaultEvent/DisplayDefaultEvent';
import DisplayDefaultMetadata from './DisplayDefaultMetadata/DisplayDefaultMetadata';
import styles from './DisplayEvent.module.css';
import DisplayLease from './DisplayLease/DisplayLease';
import DisplayPhase from './DisplayPhase/DisplayPhase';
import DisplayPriceAdjustment from './DisplayPriceAdjustment/DisplayPriceAdjustment';
import DisplayPublishedEverywhere from './DisplayPublishedEverywhere/DisplayPublishedEverywhere';

const cx = classNames.bind(styles);

const milestoneMap = {
  InspectionScheduled: 'Scheduled Your Inspection',
  InspectionCompleted: 'Completed Your Inspection',
  PostInspectionProposalSent: 'Sent Your Report',
  PostInspectionProposalApproved: 'Approved Your Report',
  ShowReady: 'Your Home Is Ready To Be Shown',
  FullListingPublished: 'Published Your Listing',
  LeaseSigned: 'Found Your Home Love',
  MoveInReady: 'Your Home Is Ready To Be Moved Into',
  ResidentMovedIn: 'Moved Your Residents In',
  ManagingAdoptedResidents: 'Started To Manage Your Residents',
  ListingRepublished: 'Published Your Listing',
  MoveInPrepped: 'Move In Prepped',
};

const phaseTriggeringMilestones = [
  'AgreementSigned',
  'ShowReady',
  'MoveInReady',
  'FullListingPublished',
  'LeaseSigned',
  'MoveInPrepped',
];

const withDefaultEvent = (dot, timelineEvent, isFirstElement, timeZone) => {
  const { timelineEventType, timestamp, name, cta, ctaName, milestones } = timelineEvent;

  const result = [];
  // Prepend the phase, if the event, triggered a phase changing milestone.
  const phaseMilestone = milestones?.find((milestone) => phaseTriggeringMilestones.includes(milestone.name));
  const phase = {
    dotContainerClassName: cx('dot-container'),
    tail: { className: cx('tail'), height: 60 },
  };

  if (phaseMilestone) {
    switch (phaseMilestone.name) {
      case 'AgreementSigned': {
        phase.dot = (
          <DisplayPhase
            title="Welcome!"
            subtitle="Your Belong journey began!"
            ilustration="/illustrations/ho_account_join_belong.svg"
            background="/illustrations/ho_account_heart.svg"
            mobileBackground="/illustrations/ho_account_heart_m.svg"
          />
        );
        result.push(phase);
        break;
      }

      case 'ShowReady': {
        phase.dot = (
          <DisplayPhase
            title="Look at that!"
            subtitle="Your home is ready to be shown!"
            ilustration="/illustrations/ho_account_ready.svg"
          />
        );
        result.push(phase);
        break;
      }

      case 'FullListingPublished': {
        phase.dot = (
          <DisplayPhase
            title="Check it out!"
            subtitle="Your listing is live!"
            ilustration="/illustrations/ho_account_listing.svg"
          />
        );
        result.push(phase);
        break;
      }

      case 'LeaseSigned': {
        phase.dot = (
          <DisplayPhase
            title="Congratulations!"
            subtitle="We found someone to love your home!"
            ilustration="/illustrations/ho_account_sign.svg"
          />
        );
        result.push(phase);
        break;
      }

      // Not a phase, but dispaying as one by product request.
      case 'MoveInReady': {
        phase.dot = (
          <DisplayPhase
            title="Alright!"
            subtitle="Your home is ready for a move in!"
            ilustration="/illustrations/ho_account_movein_ready.svg"
          />
        );
        result.push(phase);
        break;
      }

      default: {
        break;
      }
    }
  }

  // This is an exemption where we display an event as a phase.
  if (timelineEventType === 'LeaseRenewed') {
    phase.dot = (
      <DisplayPhase
        title="Hooray!"
        subtitle="Your Residents renewed!"
        ilustration="/illustrations/ho_account_sign.svg"
      />
    );
    result.push(phase);
  }

  const { cta: milestoneCta, ctaName: milestoneCtaName } = phaseMilestone || {};

  result.push(
    (name || phaseMilestone) && {
      dot: isFirstElement && <div className={cx('pulse')} />,
      right: (
        <DisplayDefaultEvent name={name || milestoneMap[phaseMilestone.name]} date={timestamp} timeZone={timeZone} />
      ),
      actions: (cta || milestoneCta) && [
        ...arrayInsertIf(cta, { value: ctaName, to: cta }),
        ...arrayInsertIf(milestoneCta && milestoneCta !== cta, {
          value: milestoneCtaName,
          to: milestoneCta,
        }),
      ],
      tail: { height: dot ? 54 : 84 },
    },
    dot && {
      dot,
      dotContainerClassName: cx('dot-container'),
      tail: { className: cx('tail'), height: 60 },
    }
  );

  return result;
};

const DisplayEvent = (timelineEvent, isFirstElement, timeZone) => {
  const { timelineEventType, timestamp, metadata = {} } = timelineEvent || {};

  switch (timelineEventType) {
    // Inspection Scheduled
    // todo: update this when cancelling the inspection
    case 'InspectionScheduled': {
      const { inspectionStatus, appointmentBy } = metadata;

      if (inspectionStatus === 'Pending' || !appointmentBy) {
        return withDefaultEvent(null, timelineEvent, isFirstElement, timeZone);
      }

      return withDefaultEvent(
        <DisplayAppointment
          date={metadata.scheduledOn}
          employeeName={`${appointmentBy.firstName} ${appointmentBy.lastName}`}
          employeeImageUrl={appointmentBy.profileImageUrl}
          timeZone={timeZone}
        />,
        timelineEvent,
        isFirstElement,
        timeZone
      );
    }

    // Improvements Plan Finalized
    case 'ImprovementsRepairsReportConfirmed': {
      return withDefaultEvent(
        metadata.estimatedStartDate ? (
          <DisplayDefaultMetadata
            metadata={{
              'Work Starting By': formatUtcDateTimeToTimeZone({
                dateTime: metadata.estimatedStartDate,
                format: DATE_FORMATS.FULL_MONTH_DAY_YEAR,
                timeZone: 'UTC',
              }),
            }}
          />
        ) : null,
        timelineEvent,
        isFirstElement
      );
    }

    // Pricing Strategy Finalized
    case 'PricingPostInspectionReportConfirmed': {
      return withDefaultEvent(
        <DisplayDefaultMetadata
          title="The Game Plan"
          metadata={{
            'Initial Listing Price': (
              <>
                <Money format={MONEY_FORMAT.DOLLARS} cash={metadata.initialListingRent} />
                /mo.
              </>
            ),
            ...(metadata.isWithFloorPrice
              ? {
                  'Automatic Pricing Until': (
                    <span>
                      <Money format={MONEY_FORMAT.DOLLARS} cash={metadata.checkInPrice} />
                      /mo. or{' '}
                      {formatDateTime({
                        dateTime: legacyParse(addWeeks(legacyParse(new Date(timestamp)), 3)),
                        format: DATE_FORMATS.FULL_MONTH_DAY_YEAR,
                      })}
                    </span>
                  ),
                }
              : {}),
          }}
        />,
        timelineEvent,
        isFirstElement
      );
    }

    // Price Adjustment
    case 'PricingAdjusted': {
      return withDefaultEvent(
        metadata.previousPrice && metadata.newPrice && metadata.previousPrice !== metadata.newPrice ? (
          <DisplayPriceAdjustment metadata={metadata} />
        ) : null,
        timelineEvent,
        isFirstElement
      );
    }

    // Lease Signed
    case 'LeaseSigned': {
      return withDefaultEvent(<DisplayLease lease={metadata} />, timelineEvent, isFirstElement);
    }

    // Lease Renewed
    case 'LeaseRenewed': {
      return withDefaultEvent(<DisplayLease lease={metadata} />, timelineEvent, isFirstElement);
    }

    // Published Everywhere
    case 'ThirdPartyListingsPublished': {
      return withDefaultEvent(<DisplayPublishedEverywhere publications={metadata} />, timelineEvent, isFirstElement);
    }

    /** Removed from v1 for lack of user friendly string mappings */
    // Lease Cancelled
    // case 'LeaseCancelled': {
    //   return withDefaultEvent(
    //     <DisplayDefaultMetadata
    //       metadata={{
    //         'Notice Type': metadata.value,
    //       }}
    //     />,
    //     timelineEvent, isFirstElement
    //   );
    // }

    default: {
      return withDefaultEvent(null, timelineEvent, isFirstElement);
    }
  }
};

DisplayEvent.propTypes = {
  timelineEvent: PropTypes.object.isRequired,
};

export default DisplayEvent;
