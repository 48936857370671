import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { GENERAL_ICONS, COLORS } from 'components/GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
import { Text } from 'design-system';
import PropTypes from 'prop-types';
import { selectToast, deleteToast } from 'store/redux/toast';
import styles from './Toast.module.css';

const cx = classNames.bind(styles);

const Toast = ({ list, metadata, deleteToastAction }) => {
  const { autoDelete, autoDeleteTime, containerClassName } = metadata;

  const stateAndProps = (id) => {
    // eslint-disable-next-line
    hideToast: () => deleteToastAction(id);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (autoDelete && list.length > 0) {
        deleteToastAction(list[list.length - 1].id);
      }
    }, autoDeleteTime);
    return () => clearTimeout(timeout);
  }, [list]);

  return list.length > 0
    ? ReactDOM.createPortal(
        // eslint-disable-next-line react/jsx-indent
        <div className={cx('toasts-container', containerClassName)}>
          {list.map(({ component, message, id, error, success, created }, i) => (
            <div
              className={cx('toast-container', { 'bg-navy': success, 'bg-red': error, 'bg-dark-green': created })}
              key={i}
            >
              <div className={cx('toast-left')}>
                {/* eslint-disable-next-line */}
                {message ? (
                  <Text fontWeight="semibold" fontSize="body">
                    {message}
                  </Text>
                ) : typeof component === 'function' ? (
                  component(stateAndProps(id))
                ) : (
                  component
                )}
              </div>
              <IconButton
                onClick={() => deleteToastAction(id)}
                generalIconProps={{ color: COLORS.WHITE, hoverColor: COLORS.WHITE }}
                icon={GENERAL_ICONS.CLOSE}
                align={ALIGN_TYPES.NONE}
              />
            </div>
          ))}
        </div>,
        document.getElementById('toast')
      )
    : null;
};

const ToastProps = {
  component: PropTypes.func,
  message: PropTypes.string,
};

Toast.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape(ToastProps)).isRequired,
  incomingToast: PropTypes.shape(ToastProps),
  metadata: PropTypes.shape({
    autoDelete: PropTypes.bool.isRequired,
    autoDeleteTime: PropTypes.number.isRequired,
  }).isRequired,
  deleteToastAction: PropTypes.func.isRequired,
};

Toast.defaultProps = {
  incomingToast: null,
};

export default connect(selectToast, { deleteToastAction: deleteToast })(Toast);
