import { useContext } from 'react';
import { flatten } from 'es-toolkit/compat';
import { MaintenanceResponsibility } from 'models/enums';
import { ApprovalContext } from 'pages/PostInspectionFlow/steps/Improvements/Approval/ApprovalContext';
import { getFlaggedItems } from 'pages/PostInspectionFlow/steps/Improvements/utils';

export const useFlaggedImprovements = () => {
  const { bundles } = useContext<any>(ApprovalContext);

  const items = flatten(bundles.map((bundle) => bundle.items));

  const flaggedImprovements = getFlaggedItems(items, MaintenanceResponsibility.Homeowner);

  return { flaggedImprovements };
};
