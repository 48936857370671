import React, { useState } from 'react';
import { Form as ReactFinalForm, FormSpy } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isEqual } from 'es-toolkit/compat';
import PropTypes from 'prop-types';
import { clearToasts, selectToast, showToast } from 'store/redux/toast';

export const FormContext = React.createContext(false);

const defaultErrorMessage = "Let's complete all required fields!";

const Form = ({
  getForm,
  getFormBottomBar,
  bottomPosition,
  rightPosition,
  errorProps,
  id,
  bottomPositionMobile,
  allowSubmitErrors,
  onSubmit,
  customError,
  ...rest
}) => {
  const [showErrors, setShowErrors] = useState(false);
  const dispatch = useDispatch();
  const { list: toastList } = useSelector(selectToast);

  const handleSubmit = async (values, form, callback) => {
    try {
      dispatch(clearToasts());
      const submissionErrors = await onSubmit(values, form, callback);

      // React final form expects you to return undefined if everything went well or an object with submission errors.
      if (allowSubmitErrors && submissionErrors) {
        dispatch(
          showToast(
            { message: submissionErrors?.[0]?.message ?? defaultErrorMessage, error: true },
            { autoDelete: true, autoDeleteTime: 4000 }
          )
        );
        setShowErrors(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const clearError = () => {
    setShowErrors(false);
  };

  return (
    <FormContext.Provider value={showErrors}>
      <ReactFinalForm
        initialValuesEqual={(values1, values2) => isEqual(values1, values2)}
        {...rest}
        onSubmit={handleSubmit}
        render={(formProps) => {
          const { invalid, errors } = formProps;

          const nextButtonProps = {
            onClick: (e) => {
              if (invalid) {
                e.preventDefault();
                let errorMessage = defaultErrorMessage;

                // If we have a custom error for one field and that's the only field left, will display a custom error
                // more context here: https://belonghome.atlassian.net/browse/HG-1235
                if (customError && Object.keys(errors.keys ?? {}).length === 1) {
                  const errorKeys = customError.split('.');
                  const hasChild = errorKeys.length > 0;

                  if (typeof errors.keys[errorKeys[0]] !== 'undefined') {
                    if (hasChild) {
                      errorMessage = errors.keys[errorKeys[0]][errorKeys[1]];
                    } else {
                      errorMessage = errors.keys[errorKeys[0]];
                    }
                  }
                }

                if (isEmpty(toastList)) {
                  dispatch(
                    showToast({ message: errorMessage, error: true }, { autoDelete: true, autoDeleteTime: 4000 })
                  );
                }
                setShowErrors(true);
              }
            },
          };

          return (
            <form id={id} onSubmit={formProps.handleSubmit} style={{ position: 'relative' }}>
              <FormSpy subscription={{ values: true }} onChange={clearError} />
              {getForm({ ...formProps })}
              {getFormBottomBar(
                formProps,
                // Spread nextButtonProps on the form submit button.
                // Ex: Advanced.js and IdentityVerification.js
                nextButtonProps,
                () => {
                  setShowErrors(false);
                }
              )}
            </form>
          );
        }}
      />
    </FormContext.Provider>
  );
};

Form.propTypes = {
  getForm: PropTypes.func.isRequired,
  showErrorsState: PropTypes.bool,
  getFormBottomBar: PropTypes.func,
  bottomPosition: PropTypes.string,
  rightPosition: PropTypes.string,
  errorProps: PropTypes.object,
  bottomPositionMobile: PropTypes.number,
  allowSubmitErrors: PropTypes.bool,
  onSubmit: PropTypes.func,
  customError: PropTypes.string,
  id: PropTypes.string,
};

Form.defaultProps = {
  showErrorsState: false,
  getFormBottomBar: () => {},
  errorProps: {},
  rightPosition: null,
};

export default Form;
