import React from 'react';
import { DateSelectorFinalFormAdapter } from 'components/DateSelector/DateSelector';
import Field from 'components/Field/Field';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import { requestorButtons } from 'containercomponents/Modals/ModalListOfItems/ApplianceList/listOfFormObjects';
import FormLayout from 'layouts/FormLayout/FormLayout';
import PropTypes from 'prop-types';
import { ADD_TASK_MODAL_STRINGS } from 'strings/add-task-modal';
import { dateSelectorAtLeastNDaysSelected } from 'utils/validation';

const ResidentAvailabilityForm = ({ isRequestorPresent }) => {
  return (
    <>
      <FormLayout.Section
        sectionSubTitle={ADD_TASK_MODAL_STRINGS['renter_upgrade_modal.requestor_present_subtitle']}
        sectionTitle={ADD_TASK_MODAL_STRINGS['renter_upgrade_modal.requestor_present_title']}
      >
        <Field
          component={SelectorFinalFormAdapter}
          buttons={requestorButtons}
          type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
          name="isRequestorPresent"
          labelClassName="px-xs"
        />
      </FormLayout.Section>

      {isRequestorPresent && (
        <FormLayout.Section
          sectionTitle={
            isRequestorPresent
              ? ADD_TASK_MODAL_STRINGS['renter_upgrade_modal.present_availability']
              : ADD_TASK_MODAL_STRINGS['renter_upgrade_modal.not_present_availability']
          }
          sectionSubTitle={ADD_TASK_MODAL_STRINGS['renter_upgrade_modal.availability_subtitle']}
        >
          <Field
            name="requestorAvailability"
            validate={dateSelectorAtLeastNDaysSelected(3)}
            standardSelector
            amountOfDays={15}
            component={DateSelectorFinalFormAdapter}
          />
        </FormLayout.Section>
      )}
    </>
  );
};

ResidentAvailabilityForm.propTypes = {
  isRequestorPresent: PropTypes.bool.isRequired,
};

export default ResidentAvailabilityForm;
