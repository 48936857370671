import { compact, isArray, isString } from 'es-toolkit/compat';
import queryString from 'query-string';

export const getStringFromJSX = (node) => {
  let string = '';

  if (isString(node)) {
    string = node;
  } else if (isArray(node)) {
    const compactedNode = compact(node);
    if (compactedNode.length === 1 && isString(compactedNode[0])) {
      const [text] = compactedNode;
      string = text;
    }
  }

  return string;
};

export const getRouteProperties = (search) => {
  const queryParams = queryString.parse(search) || {};
  const prefixedQueryParams = {};
  Object.keys(queryParams).forEach((queryParamKey) => {
    prefixedQueryParams[`qp_${queryParamKey}`] = queryParams[queryParamKey];
  });

  return {
    ...prefixedQueryParams,
  };
};
