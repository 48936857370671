import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { scroller } from 'react-scroll';
import { template, formatMoney } from '@belong/common';
import { Text, Image, ButtonBase } from '@belong/ui';
import clsx from 'clsx';
import AddOrSubtract from 'components/AddOrSubtract/AddOrSubtract';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { InputFinalFormAdapter } from 'components/Input/Input';
import TextBubbleWithUserProfile from 'components/TextBubbleWithUserProfile/TextBubbleWithUserProfile';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { Text as LegacyText } from 'design-system';
import FormCondition, { FIELD_CONDITION_COMPARISON_TYPES } from 'formcomponents/FormCondition/FormCondition';
import { Satisfaction } from 'formcomponents/satisfaction/satisfaction';
import FormLayout from 'layouts/FormLayout/FormLayout';
import PropTypes from 'prop-types';
import { showToast } from 'store/redux/toast';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';
import { getPricingAssignedEmployee } from '../utils';
import css from './CheckInPrice.module.css';

const CheckInPrice = ({ stepResource, onSaveAndNext, stepConfigs, stepConfig, onPrevious }) => {
  const assignedEmployee = getPricingAssignedEmployee(stepResource);

  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    userRequestedCheckInPrice: stepResource.details.listing?.checkInPrice,
    userCheckInPriceSentiment: stepResource.details.listing?.pricingReportDetails?.userCheckInPriceSentiment,
    userFeedbackCheckInPrice: stepResource.details.listing?.pricingReportDetails?.userFeedbackCheckInPrice,
  });

  useEffect(() => {
    setFormValues({
      userRequestedCheckInPrice: stepResource.details.listing?.checkInPrice,
      userCheckInPriceSentiment: stepResource.details.listing?.pricingReportDetails?.userCheckInPriceSentiment,
      userFeedbackCheckInPrice: stepResource.details.listing?.pricingReportDetails?.userFeedbackCheckInPrice,
    });
  }, [stepResource]);

  const isBadSentiment = (sentiment) => sentiment === 'Bad';

  const getTextBasedOffSentiment = (values) => {
    if (values.userCheckInPriceSentiment === 'Bad') {
      return POST_INSPECTION_FLOW_STRINGS['pricing.check-in.cta.initial-not-happy'];
    }

    return POST_INSPECTION_FLOW_STRINGS['pricing.check-in.cta.initial-approved'];
  };

  const handleSubmit = async (values) => {
    const updatedValues = {
      checkInPrice: {
        ...stepResource.details.listing,
        userCheckInPriceSentiment: values.userCheckInPriceSentiment,
        userFeedbackCheckInPrice: isBadSentiment(values.userCheckInPriceSentiment)
          ? values.userFeedbackCheckInPrice
          : null,
        userAcceptedCheckInPrice: !isBadSentiment(values.userCheckInPriceSentiment),
      },
    };

    if (values.userRequestedCheckInPrice !== stepResource.details.listing.checkInPrice) {
      updatedValues.checkInPrice.userRequestedCheckInPrice = values.userRequestedCheckInPrice;
      updatedValues.checkInPrice.userAcceptedCheckInPrice = false;
    }

    await onSaveAndNext(updatedValues);

    dispatch(
      showToast(
        {
          component: isBadSentiment(values.userCheckInPriceSentiment) ? (
            <Text fontWeight="semibold" className={css.toastContainer}>
              {POST_INSPECTION_FLOW_STRINGS['pricing.check-in.toast-bad-sentiment']}
            </Text>
          ) : (
            <Text fontWeight="semibold" className={css.toastContainer}>
              {template(POST_INSPECTION_FLOW_STRINGS['pricing.check-in.toast'], {
                checkInPrice: formatMoney(values.userRequestedCheckInPrice, 'DOLLARS_NO_CENTS'),
              })}
            </Text>
          ),
          success: true,
        },
        { autoDelete: true, autoDeleteTime: 4000 }
      )
    );
  };

  return (
    <>
      <PostInspectionContentLayout
        showCityBackground={false}
        noOverlay
        stepConfigs={stepConfigs}
        stepConfig={stepConfig}
        title={POST_INSPECTION_FLOW_STRINGS['pricing.check-in.title.initial-approved']}
        employee={assignedEmployee}
      >
        <Text variant="h3" fontWeight="semibold" className="mb-xl">
          {POST_INSPECTION_FLOW_STRINGS['pricing.check-in.critical.title']}
        </Text>

        <div className="flex flex-col lg:flex-row mb-3xl">
          <div className={css.imageContainer}>
            <Image src="/pricing-flow/checkin_price_graph.svg" alt="" />
          </div>
          <Text className="flex-1 mt-xl lg:ml-lg lg:mt-0">
            {POST_INSPECTION_FLOW_STRINGS['pricing.check-in.critical.content']}
          </Text>
        </div>

        <Form
          initialValues={{
            userRequestedCheckInPrice: stepResource.details.listing?.checkInPrice,
            userCheckInPriceSentiment: stepResource.details.listing?.pricingReportDetails?.userCheckInPriceSentiment,
            userFeedbackCheckInPrice: stepResource.details.listing?.pricingReportDetails?.userFeedbackCheckInPrice,
          }}
          onSubmit={handleSubmit}
          getForm={({ values }) => {
            return (
              <FormLayout>
                <Text variant="h3" fontWeight="semibold" className="mb-xl">
                  {POST_INSPECTION_FLOW_STRINGS['pricing.check-in.automatic-pricing.section-title']}
                </Text>

                <div className="mb-sm -mx-sm" style={{ height: 260 }}>
                  <Image src="/pricing-flow/rented.png" alt="" role="presentation" />
                </div>

                <div className="flex flex-1 flex-col items-center px-xs lg:px-lg" key="check-points">
                  <div className="flex flex-col justify-center items-center h1">
                    <div className={css.addOrSubtract}>
                      <Field
                        size="large"
                        name="userRequestedCheckInPrice"
                        changeAmount={25}
                        min={Math.ceil(stepResource.details.listing?.checkInPrice * 0.9)}
                        max={stepResource.details.listing?.checkInPrice}
                        component={AddOrSubtract}
                        onChangeCustom={(value) => {
                          setFormValues({
                            ...formValues,
                            userRequestedCheckInPrice: value,
                          });
                        }}
                        valueClassName="text-navy"
                      />
                      <Text fontWeight="semibold" className="flex justify-center mt-xs">
                        Floor Price
                      </Text>
                    </div>
                  </div>
                </div>

                <Text variant="p1" className="text-center mt-2xs lg:mt-2sm mb-xl">
                  {POST_INSPECTION_FLOW_STRINGS['pricing.check-in.check-points.title']}
                </Text>

                <div className="flex flex-col lg:flex-row mb-2xl">
                  <div
                    className="flex flex-1 flex-col items-center px-xs lg:px-lg mb-2xl lg:mb-0"
                    key="automatic-pricing"
                  >
                    <div className={css.iconContainer}>
                      <Image src="/pricing-flow/icon_pricing_calculation.svg" alt="" />
                    </div>

                    <Text
                      variant="p1"
                      className="text-center"
                      dangerouslySetInnerHTML={{
                        __html: POST_INSPECTION_FLOW_STRINGS['pricing.check-in.automatic-pricing.title'],
                      }}
                    />
                  </div>

                  <div
                    className={clsx(css.addOrSubtractContainer, 'flex flex-1 flex-col items-center px-xs lg:px-lg')}
                    key="weekly-updates"
                  >
                    <div className={css.iconContainer}>
                      <Image src="/pricing-flow/icon_email_updates.svg" alt="" />
                    </div>
                    <Text
                      variant="p1"
                      className="text-center"
                      dangerouslySetInnerHTML={{
                        __html: POST_INSPECTION_FLOW_STRINGS['pricing.check-in.weekly-updates.title'],
                      }}
                    />
                  </div>
                </div>

                <div className="hidden md:flex justify-center">
                  <PostInspectionContentLayout.CtaButton onClick={() => handleSubmit(values)}>
                    {getTextBasedOffSentiment(values)}
                  </PostInspectionContentLayout.CtaButton>
                </div>
                <Space value={SPACE_TYPES.XXXL} />
                <div className={css['check-in-wrapper']}>
                  <LegacyText fontSize="h3" fontWeight="semibold">
                    {POST_INSPECTION_FLOW_STRINGS['pricing.check-in.how-do-you-feel']}
                  </LegacyText>
                </div>
                <Satisfaction
                  onChangeCustom={(value) => {
                    setFormValues({
                      ...formValues,
                      userCheckInPriceSentiment: value,
                    });
                    setTimeout(() => {
                      scroller.scrollTo('userFeedbackCheckInPrice', {
                        duration: 1000,
                        smooth: true,
                      });
                    }, 150);
                  }}
                  fluid
                  validate={() => {}}
                  name="userCheckInPriceSentiment"
                />
                <FormCondition
                  conditions={[
                    [
                      {
                        fieldName: 'userCheckInPriceSentiment',
                        compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
                        fieldValue: 'Neutral',
                      },
                    ],
                    [
                      {
                        fieldName: 'userCheckInPriceSentiment',
                        compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
                        fieldValue: 'Bad',
                      },
                    ],
                  ]}
                  formValues={values}
                >
                  <Space value={SPACE_TYPES.XXL} />
                  <TextBubbleWithUserProfile
                    text={POST_INSPECTION_FLOW_STRINGS['pricing.check-in.our-strategy']}
                    employee={assignedEmployee}
                  />
                  <Space value={SPACE_TYPES.XXL} />
                  <Field
                    placeholder={POST_INSPECTION_FLOW_STRINGS['pricing.check-in.tell-us-how-you-feel']}
                    component={InputFinalFormAdapter}
                    name="userFeedbackCheckInPrice"
                  />
                  <div className="flex justify-center my-2xl">
                    <PostInspectionContentLayout.CtaButton onClick={() => handleSubmit(values)}>
                      {POST_INSPECTION_FLOW_STRINGS['pricing.check-in.cta.with-feedback']}
                    </PostInspectionContentLayout.CtaButton>
                  </div>
                </FormCondition>
                <div className="fixed bottom-0 w-full flex md:hidden -ml-2sm z-fullscreen">
                  <ButtonBase className="h-2xl flex-1 bg-navy" onClick={onPrevious}>
                    <Text fontWeight="semibold" className="text-white">
                      Previous
                    </Text>
                  </ButtonBase>

                  <ButtonBase className="h-2xl flex-1 bg-green disabled:bg-gray">
                    <Text fontWeight="semibold" className="text-white">
                      Next
                    </Text>
                  </ButtonBase>
                </div>
              </FormLayout>
            );
          }}
        />
      </PostInspectionContentLayout>
    </>
  );
};
CheckInPrice.propTypes = {
  stepResource: PropTypes.object.isRequired,
  onSaveAndNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  stepConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepConfig: PropTypes.object.isRequired,
};

export default CheckInPrice;
