export const ADD_TASK_MODAL_STRINGS = {
  // Used in Homeowner Account Modals
  title: '<span>Our Pros are</span> <span> here for you!</span>',
  label: 'First, select your Belong Home.',
  'book_a_pro.title': 'What can we help with?',
  'renter_upgrade.title': 'What type of improvement is this?',

  'upload.title': "Let's take a look!",
  'upload.subtitle.recurring': 'The more photos or video we have, the faster we can put a plan <span>in place.</span>.',
  'upload.subtitle.repair': 'The more photos or video we have, the faster we can diagnose <span>the issue.</span>',
  'upload.subtitle.upgrade_or_addition':
    'The more photos or video we have, the faster we can diagnose <span>the issue.</span>',
  'upload.subtitle.hauling': 'The more photos or video we have, the faster we can put a plan <span>in place.</span>.',
  'upload.subtitle.keys': 'The more photos or video we have, the faster we can put a plan <span>in place.</span>',
  urgency: 'How urgent is this?',
  urgency_placeholder: 'Urgency',
  issue_type: 'What can our Pros do for you\u00a0today?',
  repair: {
    title: 'What’s not working?',
    appliance_placeholder: 'Appliance, Components, Or Feature',
    describe_issue_title: 'Please describe the issue <span>in detail.</span>',
    describe_issue_subtitle: 'How did this occur? Have you tried any potential remedies? The more info the better!',
    describe_issue_placeholder: 'Issue Description',
  },

  addition: {
    title: 'What would you like to add?',
    describe_issue_placeholder: 'Specific Details',
    describe_issue_title: "Anything in particular you're looking for?",
  },

  upgrade: {
    title_homeowner: 'What would you like to upgrade?',
    title_resident: 'What would you like to upgrade?',
    describe_issue_placeholder: 'Upgrade Description',
    describe_issue_title: "Tell us more about what you'd like.",
    describe_issue_subtitle: 'Why would this improve your experience?',
  },

  preventative: {
    title: 'What would you like us to work on?',
    describe_issue_title: 'What about that would you like us to take care of?',
    describe_issue_placeholder: 'Description',
    frequency_title: 'How frequently would you like us to perform this?',
    frequency_placeholder: 'Frequency',
  },

  appliance_placeholder: 'Appliance, Components, Or Feature',

  // Used in Renter Account Modals
  'renter_repair_modal.repair_title': "What's not working?",
  'renter_repair_modal.responsibility_title': 'If we may ask...',
  'renter_repair_modal.responsibility_subtitle': 'Did you do something to cause this?',
  'renter_repair_modal.describe_issue_title': 'Please describe the issue in detail',
  'renter_repair_modal.describe_issue_subtitle': 'How did this occur? Did you try any potential remedies?',

  'renter_addition_modal.title': 'What you would like to add?',
  'renter_upgrade_modal.title': 'What would you like to upgrade?',
  'renter_upgrade_modal.improvement': 'Tell us why this would improve your living experience.',
  'renter_upgrade_modal.describe_upgrade_title': 'What would you like to upgrade?',
  'renter_upgrade_modal.describe_how_improvement_title': 'Tell us why this would improve your living experience.',
  'renter_upgrade_modal.appliance_placeholder': 'Appliance, Components, Or Feature',
  'renter_upgrade_modal.describe_issue_placeholder': 'Reason',

  'renter_upgrade_modal.present_availability': 'When are you available?',
  'renter_upgrade_modal.not_present_availability': 'When would be some good times for us to come?',

  'renter_upgrade_modal.availability_subtitle': "We'll do our best to accommodate your\u00a0schedule.",
  'renter_upgrade_modal.availability_today': 'Our Pros are generally scheduled a day in advance.',
  'renter_upgrade_modal.availability_weekend': "We don't schedule our Pros on the weekend except for emergencies.",
  'renter_upgrade_modal.requestor_present_title': 'If we need to come out...',
  'renter_upgrade_modal.requestor_present_subtitle': 'Would you like to be there?',

  haul_type_back: 'What should we strap on our back braces for?',
  haul_removal: 'What would you like removed?',
  haul_storage: 'What would you like stored?',
  haul_description: 'Anything else we should know about this? (Optional)',
  haul_description_placeholder: 'Additional details',
  key_issue: 'What key issue are you having?',
  keys_what_to_do: 'What can we do for you?',
  key_description: 'Describe the issue in detail',
  key_description_placeholder: 'Issue description (OPTIONAL)',
  key_description_placeholder_required: 'Issue description',

  maintenance_upload_image_checkbox: 'I DON’T HAVE PHOTO',
  maintenance_upload_image_tooltip: 'Note: This may delay our ability to resolve this issue.',
  which_home: 'Which home?',
  responsibility_accept:
    "Accidents happen. Thank you for your honesty! If there are any repair costs, we'll do our best to get you the best possible\u00a0price.",
  approval_required:
    "Sorry to hear that! We'll assess this issue according to Belong's Standards to determine next\u00a0steps.",
  follow_up:
    "Let's do it! We handle home improvements all the time, and will do our best to get you the best possible\u00a0price.",
  emergency:
    "Oh no! We'll do whatever we can to quickly resolve any safety or habitability issues such as water and gas leaks, lockouts, etc",
  danger: 'In immediate danger? <b>Please call 911.</b>',
};
