import { useState, useEffect } from 'react';
import Measure from 'react-measure';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { template, PATHS, BASE_PATHS } from '@belong/common';
import { Text } from '@belong/ui';
import classNames from 'classnames/bind';
import { clsx } from 'clsx';
import { filter, find, maxBy, flatten } from 'es-toolkit/compat';
import { useModal } from 'hooks/useModal';
import { MaintenanceBundleRewardType, MaintenanceResponsibility, MaintenanceBundleType } from 'models/enums';
import { GuarantedTimeToListModal } from 'pages/PostInspectionFlow/GTTLModal/GTTLModal';
import ImprovementModal, { IMPROVEMENT_FROM } from 'pages/PostInspectionFlow/ImprovementModal/ImprovementModal';
import { ApprovalForm } from 'pages/PostInspectionFlow/approval-form/approval-form';
import { EVENTS } from 'pages/PostInspectionFlow/constants';
import { ImprovementsReportSavings } from 'pages/PostInspectionFlow/improvements-report-savings/improvements-report-savings';
import { HomeownerReceiptPriceTable } from 'pages/PostInspectionFlow/steps/Improvements/Approval/PriceTable/PriceTable';
import { getInspectionType, QUERY_PARAMS, getCanExclusivityBeSelected } from 'pages/PostInspectionFlow/utils';
import { ImprovementsTotalCost } from 'post-inspection/components/improvements-total-cost/improvements-total-cost';
import { PaymentModal } from 'post-inspection/components/payment-modal/payment-modal';
import { PreMoveoutSubtotalSection } from 'post-inspection/components/pre-moveout-subtotal-section/pre-moveout-subtotal-section';
import PropTypes from 'prop-types';
import { fetchImprovementPresets } from 'store/redux/home-inspection-flow';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';
import { TodayTimeCountDown } from '../Components/DateTimeCountDown/DateTimeCountDown';
import { getCalculatedBundles, getImprovementsAssignedEmployee } from '../utils';
import styles from './Approval.module.css';
import { ApprovalContext } from './ApprovalContext';
import { COST_TYPES } from './Bundle/costs.utils';
import BundlesList from './BundlesList/BundlesList';
import { Recommended } from './Recommended/Recommended';

const cx = classNames.bind(styles);

const propTypes = {
  stepConfig: PropTypes.object.isRequired,
  stepResource: PropTypes.object.isRequired,
  stepData: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  flow: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  stepConfigs: PropTypes.array.isRequired,
};

const Approval = ({ stepResource, stepData, stepConfig, onSave, onNext, flow, stepConfigs }) => {
  if (!stepData || !flow) {
    return null;
  }

  const { days: guaranteedTimeToLeaseDays, disqualifiedReason: guaranteedTimeToLeaseDisqualifiedReason } =
    stepData?.guaranteedTimeToLease || {};

  const {
    configuration: { leaseId: leaseUniqueId },
  } = flow;

  const [loader, setLoader] = useState({ loading: false });
  const [initialBundleHeight, setInitialBundleHeight] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setModalOpen, setModalClose] = useModal(false);
  const [isGTTLModalOpen, closeGTTLModal] = useModal(false);
  const [isPPModalOpen, setPPModalOpen, setPPModalClose] = useModal(false);
  const [isLoading, startLoading, stopLoading] = useModal(false);
  const [currentImprovementId, setCurrentImprovementId] = useState(null);
  // Leaving isMonthly definition here to avoid a bigger refactor, this logic is now legacy.
  const isMonthly = false;
  const [isApprovingReport, setIsApprovingReport] = useState(false);
  const [isComplimentaryRepairsExclusivitySelected, setIsComplimentaryRepairsExclusivitySelected] = useState(false);
  const [improvementPresets, setImprovementPresets] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    bundles: bundlesData,
    groupPayment,
    group,
    rewards,
    homeownerReportPaymentModel,
    displayAsPriceRange,
    priceRange,
    guaranteedTimeToList,
    isDoNotExceedGroup,
  } = stepData || {};

  const inspectionType = getInspectionType(flow);

  const { isLegacyInvoicing, estimatedTotal } = group || {};

  const canExclusivityBeSelected = getCanExclusivityBeSelected({
    isLegacyInvoicing,
    estimatedTotal,
    isOnboardingInspection: inspectionType.isOnboarding,
    isDoNotExceedGroup,
  });

  useEffect(() => {
    if (canExclusivityBeSelected) {
      window.FS?.event?.(EVENTS.EXCLUSIVITY_ENABLED, {});
    }
  }, [canExclusivityBeSelected]);

  useEffect(() => {
    const fetchPresets = async () => {
      try {
        const { attributes } = await dispatch(fetchImprovementPresets());
        const presets = JSON.parse(attributes);
        setImprovementPresets(presets);
      } catch (error) {
        console.error('Error fetching improvement presets:', error);
        setImprovementPresets([]);
      }
    };

    fetchPresets();
  }, [dispatch]);

  if (!bundlesData) {
    return null;
  }

  /**
   * The homeowner cannot go back to this step once the report is approved.
   */
  if (stepConfig.status === 'Completed' && !isApprovingReport) {
    history.push(
      `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${stepConfig.home.propertyId}/setup-flow?${QUERY_PARAMS.REPORT_APPROVED}=true`
    );

    return null;
  }

  const homeownerPayment = find(groupPayment, { paidBy: MaintenanceResponsibility.Homeowner });

  const {
    homeListingDetailsModel: { employeeAssignments, leases },
  } = stepResource;

  const currentLease = leases.find((lease) => lease.basicInfo.id === leaseUniqueId);
  const isAdoptedHome = currentLease?.basicInfo?.areResidentsAdopted;

  const bundles = getCalculatedBundles(bundlesData, stepResource.homeListingDetailsModel);
  const maximumMonthlyPaymentPlan =
    maxBy(
      filter(rewards, (reward) => reward.type === MaintenanceBundleRewardType.MonthlyInstallment && !reward.locked),
      (reward) => reward.value
    )?.value || 0;

  const improvements = flatten(bundles.map((bundle) => bundle.items));

  const mainPointOfContactEmployee = getImprovementsAssignedEmployee({
    employeeAssignments,
    isMoveOut: inspectionType.isMoveOut,
  });

  const nonRequiredBundles = filter(
    bundles,
    (bundle) => bundle.type !== MaintenanceBundleType.Inspirational && bundle.type !== MaintenanceBundleType.Recommended
  );

  const optionalBundleTypes = [MaintenanceBundleType.Inspirational, MaintenanceBundleType.Recommended];

  const optionalBundles = filter(bundles, (bundle) => optionalBundleTypes.includes(bundle.type));

  const handleChange = async (value, items) => {
    try {
      setLoader({
        value,
        loading: true,
        items,
        loadingKey: new Date().getTime().toString(),
      });

      await onSave({
        bundles: [
          {
            items: items.map((item) => {
              const itemHomeownerPayment =
                find(item.maintenancePayments, (mp) => mp.paidBy === MaintenanceResponsibility.Homeowner) || {};

              const itemNonHomeownerPayments = filter(
                item.maintenancePayments,
                (mp) => mp.paidBy !== MaintenanceResponsibility.Homeowner
              );

              return {
                ...item,
                maintenancePayments: [
                  ...itemNonHomeownerPayments.map((mp) => ({
                    ...mp,
                    flagged: false,
                  })),
                  {
                    ...itemHomeownerPayment,
                    paidBy: MaintenanceResponsibility.Homeowner,
                    consentStatus: value ? 'Approved' : 'Rejected',
                    flagged: !!item.maintenancePayments[0].flagged,
                  },
                ],
              };
            }),
          },
        ],
      });
    } catch (error) {
      console.error('Error occurred on the HO aproval flow:', error);
      history.push(PATHS.SUPPORT);
    } finally {
      setLoader((state) => ({
        ...state,
        loading: false,
        items: null,
        value: null,
      }));
    }
  };

  const handleGTTLModalConfirmation = async () => {
    try {
      startLoading();

      const maintenancewithHomeownerProServiceResponsibility = improvements.filter(
        (improvement) => improvement.maintenance.proServiceResponsibility === MaintenanceResponsibility.Homeowner
      );

      const payload = maintenancewithHomeownerProServiceResponsibility.map((improvement) => ({
        ...improvement,
        maintenance: {
          ...improvement.maintenance,
          proServiceResponsibility: MaintenanceResponsibility.Belong,
        },
      }));

      await handleChange(true, payload);
    } catch (e) {
      console.error(e);
    } finally {
      closeGTTLModal();
      stopLoading();
    }
  };

  const handleModalSubmit = async (values) => {
    const updatedBundles = {
      bundles: [
        {
          items: [values],
        },
      ],
    };

    try {
      startLoading();
      await onSave(updatedBundles);
    } catch (e) {
      console.error(e);
    }
    stopLoading();
    setModalClose();
  };

  const handleApprovalStepSubmit = async (paymentData) => {
    try {
      setIsApprovingReport(true);

      await onSave(paymentData);

      return true;
    } catch (error) {
      console.error(error);
      setIsApprovingReport(false);
      return false;
    }
  };

  const showTimer = !!group?.earlyEstimatedWorkStartDate;

  const getTitleAndSubtitles = () => {
    if (isAdoptedHome) {
      return {
        title: POST_INSPECTION_FLOW_STRINGS['approvals.title.adpted_lease'],
        optionalBundleTitle: POST_INSPECTION_FLOW_STRINGS['approvals.inspirational.title'],
        optionalBundleSubtitle: POST_INSPECTION_FLOW_STRINGS['approvals.inspirational.subtext'],
      };
    }

    if (inspectionType.isPreMoveOut) {
      return {
        title: POST_INSPECTION_FLOW_STRINGS['approvals.title_pre_move_out'],
        optionalBundleTitle: POST_INSPECTION_FLOW_STRINGS['approvals.inspirational.title_move_out'],
        optionalBundleSubtitle: POST_INSPECTION_FLOW_STRINGS['approvals.inspirational.subtext_move_out'],
      };
    }

    if (inspectionType.isMoveOut) {
      return {
        title: POST_INSPECTION_FLOW_STRINGS['approvals.title_move_out'],
        optionalBundleTitle: POST_INSPECTION_FLOW_STRINGS['approvals.inspirational.title_move_out'],
        optionalBundleSubtitle: POST_INSPECTION_FLOW_STRINGS['approvals.inspirational.subtext_move_out'],
      };
    }

    return {
      title: POST_INSPECTION_FLOW_STRINGS['approvals.title'],
      optionalBundleTitle: POST_INSPECTION_FLOW_STRINGS['approvals.inspirational.title'],
      optionalBundleSubtitle: POST_INSPECTION_FLOW_STRINGS['approvals.inspirational.subtext'],
    };
  };

  const { title, optionalBundleTitle, optionalBundleSubtitle } = getTitleAndSubtitles();

  const lineItemCosts = [COST_TYPES.Parts, COST_TYPES.Other, COST_TYPES.Labor];
  const bundleItems = flatten(bundles.map((b) => b.items));
  const isNewProjectManagementFeeInspection = flatten(bundleItems.map((i) => i.estimatedCostBreakdown))
    .filter((costs) => !lineItemCosts.includes(costs.type))
    .filter((costs) => costs.cost > 0)
    .every((cost) => cost.type === COST_TYPES.ProjectCoordination);

  const shouldShowProjectManagementFee =
    !isDoNotExceedGroup &&
    (inspectionType.isMoveOut || inspectionType.isOnboarding) &&
    isNewProjectManagementFeeInspection;

  const isGuaranteedTimeToLease = guaranteedTimeToLeaseDays > 0 && !guaranteedTimeToLeaseDisqualifiedReason;

  const bundleList = shouldShowProjectManagementFee
    ? [...nonRequiredBundles, { type: 'ProjectManagement', total: stepData.projectManagementFeeToPay }]
    : nonRequiredBundles;

  /** NOTE: Checking first if the main reason is the discualified by "ApprovedTBDSubtask"
   * but whenever there are TBD items and "HomeownerFixing" as diqualified reason this reason will the main reason
   * That's why we still need to check if there are TBD items in the bundles.
   **/
  const isGTTLDisqualifiedDueTBDItems =
    guaranteedTimeToList?.disqualifiedReason === 'ApprovedTBDSubtask' ||
    bundlesData
      ?.filter((bundle) => !optionalBundleTypes.includes(bundle.type))
      ?.map((bundle) => bundle?.items?.map((item) => !!item?.isTBD))
      ?.flat()
      ?.includes(true);

  const isGTTLVisible =
    !guaranteedTimeToList?.disqualifiedReason && inspectionType.isOnboarding && !inspectionType.isAdoptedLease;

  const isGTTLDisqualifiedDueHomeownerFixingItems =
    !isGTTLVisible && guaranteedTimeToList?.disqualifiedReason === 'HomeownerFixing' && !isGTTLDisqualifiedDueTBDItems;

  const handleBundleResize = ({ bounds }) => {
    if (!initialBundleHeight) {
      setInitialBundleHeight(bounds.height);
    }
  };

  const showDefaultDisclaimer = isGTTLDisqualifiedDueTBDItems;
  const shouldShowSubtotalsDivider =
    !inspectionType?.isOnboarding ||
    (inspectionType?.isOnboarding && inspectionType?.isAdoptedLease) ||
    homeownerPayment?.estimatedDiscountAmount > 0;

  const approvalTitle = () => {
    if (canExclusivityBeSelected) {
      return (
        <p>
          {template(POST_INSPECTION_FLOW_STRINGS['approvals.title.ready_to_rent'], {
            date: guaranteedTimeToLeaseDays,
          })}
        </p>
      );
    }

    if (isGuaranteedTimeToLease) {
      return (
        <p>
          {template(POST_INSPECTION_FLOW_STRINGS['approvals.title_with_gttlease'], {
            date: guaranteedTimeToLeaseDays,
          })}
        </p>
      );
    }

    return title;
  };

  async function handleComplimentaryRepairsOptionChange(newComplimentaryRepairsOption) {
    const isComplimentaryRepairsWithExclusivitySelected =
      newComplimentaryRepairsOption === 'complimentary-repairs-with-exclusivity';

    setIsComplimentaryRepairsExclusivitySelected(isComplimentaryRepairsWithExclusivitySelected);

    if (isComplimentaryRepairsWithExclusivitySelected) {
      const itemsWithBelongResponsibility = bundleList
        .flatMap((bundle) => bundle.items)
        .map((item) => ({
          ...item,
          maintenance: {
            ...item.maintenance,
            proServiceResponsibility: 'Belong',
          },
        }));

      await handleChange(true, itemsWithBelongResponsibility);
    }
  }

  function handleApprovalFormSubmit() {
    setPPModalOpen();
  }

  return (
    <ApprovalContext.Provider
      value={{
        guaranteedTimeToList,
        maximumMonthlyPaymentPlan,
        groupPayment,
        homeownerPayment,
        group,
        showTimer,
        flow,
        loader,
        bundles,
        rewards,
        onNext,
        onSave,
        onImprovementToggle: handleChange,
        homeownerReportPayment: homeownerReportPaymentModel,
        onApprovalStepSubmit: handleApprovalStepSubmit,
        inspectionType,
        isSubmitting,
        setIsSubmitting,
        mainPointOfContactEmployee,
        isNewProjectManagementFee: shouldShowProjectManagementFee,
        priceRange: displayAsPriceRange ? priceRange : null,
        isDoNotExceedGroup,
        isLegacyInvoicing,
        isComplimentaryRepairsExclusivitySelected,
        canExclusivityBeSelected,
      }}
    >
      <PostInspectionContentLayout
        showCityBackground
        headerBorder={false}
        isLogoVisible={false}
        title={approvalTitle()}
        employee={mainPointOfContactEmployee}
        stepConfig={stepConfig}
        stepConfigs={stepConfigs}
      >
        <div className={cx('approval')}>
          {isGuaranteedTimeToLease && (
            <p className="body mb-lg text-center">
              Confirm these improvements in the next
              <TodayTimeCountDown />
              to
              <br className="hidden md:inline" />
              <span className="ml-2xs">{`lock in your guaranteed time to\u00a0rent.`}</span>
            </p>
          )}

          {showDefaultDisclaimer && (
            <div className="flex items-center mb-xl md:items-start">
              <img
                className="mb-xl md:mb-0"
                style={{ width: '54px', height: '48px' }}
                src="/image_assets/inspection_report_approval.svg"
                alt="transparent-pricing"
              />
              <p className="p1 ml-xs">
                We believe in transparent pricing, which means that all costs are directly passed through to our
                community members. We’re constantly working with our contractors and vendors to provide you the lowest
                prices.
              </p>
            </div>
          )}

          <Measure bounds onResize={handleBundleResize}>
            {({ measureRef }) => (
              <div className={`mb-xl ${inspectionType?.isMoveOut ? 'mt-2xl' : 'mt-0'}`} ref={measureRef}>
                <BundlesList
                  bundles={bundleList}
                  projectManagementFeeApplicable={shouldShowProjectManagementFee}
                  onChange={handleChange}
                  isMonthly={isMonthly}
                  displayPrice={!isDoNotExceedGroup}
                  isDoNotExceedGroup={isDoNotExceedGroup}
                  onClick={(item) => {
                    setModalOpen();
                    setCurrentImprovementId(item.maintenance.uniqueId);
                  }}
                  priceRange={displayAsPriceRange ? priceRange : null}
                  inspectionType={inspectionType}
                  isGTTLDisqualifiedDueHomeownerFixingItems={isGTTLDisqualifiedDueHomeownerFixingItems}
                  improvementPresets={improvementPresets}
                />
              </div>
            )}
          </Measure>

          {inspectionType.isPreMoveOut && (
            <div className="mb-sm">
              <PreMoveoutSubtotalSection
                groupPayment={groupPayment}
                homeownerPayment={homeownerPayment}
                priceRange={displayAsPriceRange ? priceRange : null}
              />
            </div>
          )}
          <div className="mb-sm">
            <HomeownerReceiptPriceTable
              inspectionType={inspectionType}
              groupPayment={groupPayment}
              bundles={bundles}
              homeownerPayment={homeownerPayment}
              maximumMonthlyPaymentPlan={maximumMonthlyPaymentPlan}
              onChange={() => {}}
              homeownerReportPayment={homeownerReportPaymentModel}
              priceRange={displayAsPriceRange ? priceRange : null}
              hideBundlesBreakdown
              showSubtotal={false}
              showEstTotal={false}
            />
          </div>
          <div className={shouldShowSubtotalsDivider ? 'my-md' : '-mt-sm mb-xl'}>
            <div className="border-b border-dashed border-gray border-t-0 border-l-0 border-r-0" />
          </div>
          <div className="mb-xl">
            {canExclusivityBeSelected && isComplimentaryRepairsExclusivitySelected ? (
              <ImprovementsReportSavings cost={group?.estimatedTotal} />
            ) : (
              <ImprovementsTotalCost
                inspectionType={inspectionType}
                homeownerPayment={homeownerPayment}
                isMonthly={isMonthly}
                maximumMonthlyPaymentPlanInstallmentCount={maximumMonthlyPaymentPlan}
                homeownerReportPayment={homeownerReportPaymentModel}
                priceRange={displayAsPriceRange ? priceRange : null}
                displayNeverExceed={isDoNotExceedGroup}
              />
            )}
          </div>
          <div className={clsx(optionalBundles?.length > 0 ? 'mb-3xl' : 'mb-3xl')}>
            <ApprovalForm
              isPreMoveOutInspection={inspectionType.isPreMoveOut}
              onComplimentaryRepairsOptionChange={handleComplimentaryRepairsOptionChange}
              onSubmit={handleApprovalFormSubmit}
              canExclusivityBeSelected={canExclusivityBeSelected}
              isComplimentaryRepairsExclusivitySelected={isComplimentaryRepairsExclusivitySelected}
            />
          </div>
          {optionalBundles?.length > 0 && (
            <div className={cx('inspirational-list-container')}>
              <Text variant="h3" fontWeight="semibold" className="mb-xs pt-lg md:pt-0">
                {optionalBundleTitle}
              </Text>

              <Text className="mb-sm">{optionalBundleSubtitle}</Text>

              <div className="mt-xl">
                <Recommended
                  bundles={optionalBundles}
                  onChange={handleChange}
                  onClick={(item) => {
                    setModalOpen();
                    setCurrentImprovementId(item.maintenance.uniqueId);
                  }}
                  showPrice={!isDoNotExceedGroup}
                />
              </div>
            </div>
          )}
        </div>
        {currentImprovementId && isModalOpen && (
          <ImprovementModal
            loading={isLoading}
            inspectionType={inspectionType}
            show={isModalOpen}
            onHide={setModalClose}
            rewards={rewards}
            groupPayment={groupPayment}
            improvement={find(improvements, (item) => item.maintenance.uniqueId === currentImprovementId)}
            onSubmit={handleModalSubmit}
            onChange={handleChange}
            showPriceBreakdown={!isDoNotExceedGroup}
            showResponsibilityPercentage={group.showResponsibilityPercentage}
            from={IMPROVEMENT_FROM.IMPROVEMENT_REPORT}
            onSave={onSave}
            isDoNotExceedGroup={isDoNotExceedGroup}
            improvementPresets={improvementPresets}
          />
        )}
        <PaymentModal
          isVisible={isPPModalOpen}
          onHide={setPPModalClose}
          inspectionType={inspectionType}
          isComplimentaryRepairsWithExclusivitySelected={isComplimentaryRepairsExclusivitySelected}
          canExclusivityBeSelected={canExclusivityBeSelected}
          variant="secondary"
        />
        <GuarantedTimeToListModal
          isVisible={isGTTLModalOpen}
          onDismiss={closeGTTLModal}
          handleSuccessCallback={handleGTTLModalConfirmation}
          isLoading={isLoading}
        />
      </PostInspectionContentLayout>
    </ApprovalContext.Provider>
  );
};

Approval.propTypes = propTypes;

export default Approval;
