import { EMAIL, DEFAULT_PHONE, formatPhoneNumber } from '@belong/common';

// We decided with Lily to go with a single default for all regions for both AEs and Res Growth.
export const getDefaultHomeownerGrowth = () => {
  return {
    employee: {
      quote: '"It is our choices that show what we truly are, far more than our abilities."',
      bannerImage: 'https://s3-us-west-1.amazonaws.com/bln-prd-employee-banner-images/127521-925c70579b9deb69',
      phone: { link: DEFAULT_PHONE, display: formatPhoneNumber(DEFAULT_PHONE) },
      email: EMAIL,
    },
    employeeAssignment: {
      assignmentType: 'BelongTeamMember',
      sourceType: 'Home',
    },
    user: {
      firstName: 'Zechariah',
      lastName: 'Haws',
      memberSince: '2022-09-12T20:40:37.434533+00:00',
      name: 'Zechariah Haws',
      profileImageUrl: 'https://s3-us-west-1.amazonaws.com/bln-prd-usr-pics/127521-4e08f5ca14b2023d',
      userId: 'f163f9d10dbc788a',
      aboutMe: `Zech is a Santa Barbara, CA native who graduated with his BA in Political Science from the University of Utah. During his undergrad, he worked in the offices of U.S. Senator Orrin Hatch, Utah Governor Gary Herbert, and Salt Lake County Mayor Ben McAdams. After graduating, Zech joined tech start-up Pluralsight and became a licensed Realtor to pursue his passion for real estate. Zech then continued his education at BYU Law School. He hopes to have a career building innovative, disruptive companies while surrounded by brilliant, inspiring people. Zech is an avid surfer, Lakers fan, and extreme sports enthusiast who enjoys eating anything that can be cooked over an open fire or thrown in a smoker!`,
      homeTown: 'Santa Barbara, CA',
    },
  };
};

export const getDefaultResidentGrowth = () => {
  return {
    employee: {
      quote: `"Man cannot remake himself without suffering, for he is both the marble and the sculptor."`,
      bannerImage: 'https://s3-us-west-1.amazonaws.com/bln-prd-employee-banner-images/181879-4fda09a93cb186f5',
      phone: { link: DEFAULT_PHONE, display: formatPhoneNumber(DEFAULT_PHONE) },
      email: EMAIL,
    },
    employeeAssignment: {
      sourceType: 'Home',
      assignmentType: 'ResidentGrowth',
    },
    user: {
      firstName: 'Vincent',
      lastName: 'Genovesi',
      memberSince: '2023-04-10T20:55:27.0087037+00:00',
      name: 'Vincent Genovesi',
      profileImageUrl: 'https://s3-us-west-1.amazonaws.com/bln-prd-usr-pics/181879-b1b5f2d32fe404d5',
      userId: 'ce9a8837959cb262',
      aboutMe:
        "I am a fixed-gear cyclist and a yoga instructor in my free time. I have two little dogs, a few birds, and a home full of plants. My favorite book is The Power of Now by Eckhart Tolle. I've been in the homes industry for several years and I absolutely love my career. Helping people is my greatest passion by far.",
      homeTown: 'Salt Lake City, UT',
    },
  };
};
