import { isEmpty, isNil } from 'es-toolkit/compat';
import { uploadDocumentToS3 } from '../common/uploadDocumentToS3';
import { getApiActionTypesValues } from '../redux_utils';
import { HOMEOWNER_SET_UP_FLOW } from './constants';

export const fetchHomeownerSetUpId = (flowId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues(HOMEOWNER_SET_UP_FLOW.FETCH_HOMEOWNER_SETUP_ID),
    promise: ({ client }) => client.get(`/homeowners/set-up/${flowId}`),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const createHomeownerSetUpId = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues(HOMEOWNER_SET_UP_FLOW.CREATE_HOMEOWNER_SETUP),
    promise: ({ client }) => client.post('/homeowners/set-up'),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const fetchStepData = (flowId, mappedStep) => ({
  types: getApiActionTypesValues(HOMEOWNER_SET_UP_FLOW.FETCH_STEP_DATA),
  promise: ({ client }) =>
    client.get(`/homeowners/set-up/${flowId}/${mappedStep?.group?.key}/${mappedStep?.key}/${mappedStep?.uniqueId}`),
  auth: true,
});

export const updateStepData = (flowId, mappedStep, stepFormData) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues(HOMEOWNER_SET_UP_FLOW.UPDATE_STEP_DATA),
    promise: ({ client }) =>
      client.put(`/homeowners/set-up/${flowId}/${mappedStep.group.key}/${mappedStep.key}/${mappedStep.uniqueId}`, {
        ...stepFormData,
      }),
    auth: true,
  });

export const fetchAvailabilityDates = (homeId, startDate, endDate) => ({
  types: getApiActionTypesValues(HOMEOWNER_SET_UP_FLOW.FETCH_AVAILABILITY_DATES),
  promise: ({ client }) =>
    client.get(`/inspections/onboarding/availability-dates`, {
      params: {
        homeId,
        startDate,
        endDate,
      },
    }),
  auth: true,
});

export const getBannerImageUploadUrl = (flowId, stepId) => ({
  promise: ({ client }) => client.get(`/homeowners/set-up/${flowId}/unit/features/banner-url/${stepId}`),
  auth: true,
});

export const uploadBannerImage = (media, flowId, stepId) => async (dispatch) => {
  if (!media) return {};
  const { availableMedia, stagingMedia, deletedMedia, later } = media;
  const uploadUrlObject = await dispatch(getBannerImageUploadUrl(flowId, stepId));
  const { uniqueId, headers, uploadUrl } = uploadUrlObject;
  if (isEmpty(stagingMedia) && later) return {};
  if (isNil(stagingMedia) && isNil(deletedMedia)) return availableMedia[0];
  await uploadDocumentToS3(dispatch, stagingMedia[0], headers, uploadUrl);
  return { mediaId: uniqueId, name: stagingMedia[0].name };
};
