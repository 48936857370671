import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EMPLOYEE_STRINGS } from 'accounts/constants/strings/employee.strings';
import { useListings } from 'accounts/hooks/homeowners/use-listings';
import { isNil } from 'es-toolkit/compat';
import { EmployeeAssignmentTypes, ReportType } from 'models/enums';
import { selectUserReports } from 'store/redux/user/selectors';
import { useSelectedProperty } from './use-selected-property';

export function useTeamEmployees({ properties, units }) {
  const [employees, setEmployees] = useState([]);
  const { selectedProperty } = useSelectedProperty();
  const { listings } = useListings();
  const reports = useSelector(selectUserReports);
  const [loading, setLoading] = useState<boolean>(true);

  const getEmployees = useCallback(async () => {
    if (properties && reports && listings && units && selectedProperty) {
      setLoading(true);

      const selectedUnit = selectedProperty.getUnitsWithAgreements()?.[0];

      const homeListing = listings.find(
        (listing) => listing.homeListingDetailsModel.basicInfo.unitId === selectedUnit?.basicInfo.unitId
      );

      const pricingReportsByHomeId = reports.filter(
        ({ report }) => report.homeId === selectedUnit?.basicInfo.unitId && report.type === ReportType.Pricing
      );

      const improvementsReportsByHomeId = reports.filter(
        ({ report }) => report.homeId === selectedUnit?.basicInfo.unitId && report.type === ReportType.Improvements
      );

      const pricingEmployees = pricingReportsByHomeId?.flatMap((report) => report.employeeAssignments);

      const improvementsEmployees = improvementsReportsByHomeId?.flatMap((report) => report.employeeAssignments);

      let newEmployees = [...selectedUnit.employeeAssignments, ...pricingEmployees, ...improvementsEmployees];

      newEmployees = newEmployees.concat(homeListing.homeListingDetailsModel.employeeAssignments || []);

      const improvementsSpecialist = newEmployees.find(
        (employee) => employee?.employeeAssignment?.assignmentType === EmployeeAssignmentTypes.ImprovementsSpecialist
      );

      const concierge = newEmployees.find(
        (employee) => employee?.employeeAssignment?.assignmentType === EmployeeAssignmentTypes.Concierge
      );

      const homeownerGrowth = newEmployees.find(
        (employee) => employee?.employeeAssignment?.assignmentType === EmployeeAssignmentTypes.HomeownerGrowth
      );

      const mainPointOfContact = concierge ?? homeownerGrowth;

      const mainPointOfContactWithOrder = mainPointOfContact
        ? {
            ...mainPointOfContact,
            order: [EmployeeAssignmentTypes.HomeownerGrowth, EmployeeAssignmentTypes.Concierge].includes(
              mainPointOfContact?.employeeAssignment?.assignmentType
            ),
          }
        : null;

      const residentGrowth = newEmployees.find(
        (employee) => employee?.employeeAssignment?.assignmentType === EmployeeAssignmentTypes.ResidentGrowth
      );

      const formattedPro = [
        ...(homeListing?.homeListingDetailsModel?.inspectedBy
          ? [
              {
                ...homeListing?.homeListingDetailsModel?.inspectedBy?.employeeDetailModel,
                // Just to give it regular employee shape to format in the same way.
                employee: {
                  ...homeListing?.homeListingDetailsModel?.inspectedBy?.employeeDetailModel?.employee,
                  ...homeListing?.homeListingDetailsModel?.inspectedBy,
                },
                employeeAssignment: {
                  assignmentType: EmployeeAssignmentTypes.Pro,
                },
              },
            ]
          : []),
      ];

      newEmployees = [
        ...formattedPro,
        ...(residentGrowth
          ? [pricingEmployees?.[0], mainPointOfContactWithOrder, residentGrowth]
          : [mainPointOfContactWithOrder, pricingEmployees?.[0]]),
        improvementsSpecialist,
      ];

      newEmployees = newEmployees.filter(
        (employee) =>
          // Just in case find somehow doesn't find the item we have undefined elements
          !isNil(employee)
      );

      const formattedEmployees = newEmployees
        .map(({ employee, employeeAssignment, user, ...rest }) => ({
          ...employeeAssignment,
          ...employee,
          ...user,
          profileImage: user?.profileImageUrl,
          bannerImage: employee?.bannerImageUrl ?? employee?.bannerUrl,
          jobTitle: EMPLOYEE_STRINGS[employeeAssignment?.assignmentType],
          ...rest,
        }))
        .filter(
          (formattedEmployee) =>
            formattedEmployee.bannerImage && formattedEmployee.profileImage && formattedEmployee.profileImageUrl
        );

      setEmployees(formattedEmployees);
      setLoading(false);
    }
  }, [listings, properties, reports, selectedProperty, units]);

  useEffect(() => {
    getEmployees();
  }, [properties, reports, listings, getEmployees]);

  return { employees, loading };
}
