import 'react-day-picker/dist/style.css';
import { createRoot } from 'react-dom/client';
import { pdfjs } from 'react-pdf';
import { Provider } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import { COOKIES_CONFIG, ENVIRONMENTS } from '@belong/common';
// Webpack is not finding @belong/ui/index.css for some reason, works for the rest of the exports
import '@belong/ui/dist/index.css';
import { datadogRum } from '@datadog/browser-rum';
import * as FullStory from '@fullstory/browser';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import '@reach/accordion/styles.css';
import '@reach/dialog/styles.css';
import '@reach/slider/styles.css';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import { AnalyticsProvider } from 'analytics';
import { setEnhancedConvertionData, pageview } from 'analytics/ga.utils';
import { ApiClientProvider } from 'common/contexts/api-client';
import ReduxAsyncConnect from 'components/ReduxAsyncConnect/ReduxAsyncConnect';
import config from 'config/config';
import { ConnectedRouter } from 'connected-react-router';
import 'core-js/stable';
import { isEmpty } from 'es-toolkit/compat';
import { GoogleAnalytics } from 'helpers/GoogleAnalytics';
import { GoogleTagManager } from 'helpers/GoogleTagManager';
import apiClient from 'helpers/apiClient';
import buildOctaneClient from 'helpers/buildOctaneClient';
import { RedditPixel } from 'helpers/reddit-pixel';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import { parseCookies } from 'nookies';
import App from 'pages/App/App';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import 'regenerator-runtime/runtime';
import 'resize-observer-polyfill';
import createStore from 'store/redux/create';
import createReducers from 'store/redux/reducers';
import { fetchUser } from 'store/redux/user/actions';
import theme from 'styles/theme';
import { ThemeProvider } from 'theme-ui';
import { createHistory } from 'utils/history';
import uuidv1 from 'uuid/v1';
import './index.css';
import { trackSiteVisit } from './store/redux/common/uploadDocumentToS3';
import { selectExperiments, updateExperiments } from './store/redux/experiments';
import { HOMEOWNER_ONBARDING_LEADRS_REDUCER } from './store/redux/homeowner-onboarding/leads-reducer';
import { selectUser } from './store/redux/user/selectors';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

console.log('Build Time:', new Date(__BUILD_TIME__));
console.log('Build Number:', __BUILD_NUMBER__);

const { JWT_TOKEN } = COOKIES_CONFIG;

const { env, mixpanelApiKey } = config;
const accessibilityPreferences = {
  themePreference: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
  languagePreference: window.navigator.language,
  reducedMotionPreference: window.matchMedia('(prefers-reduced-motion: reduce)').matches,
  isHighContrastModePreference: window.matchMedia('(forced-colors: active)').matches,
};

if (mixpanelApiKey) {
  window.mixpanel?.init(mixpanelApiKey);
  window.mixpanel?.register({
    'First Visited URL': window.location.href,
    'First Visited Path': window.location.pathname,
    ...accessibilityPreferences,
  });
}

const client = apiClient();
const octaneClient = buildOctaneClient();
const cookies = parseCookies();
const jwtToken = cookies[JWT_TOKEN.name];

const history = createHistory();

// eslint-disable-next-line
let error = console.error;
console.error = function (warning) {
  if (/Failed %s type/.test(warning)) {
    // alert(warning);
    return;
  }
  // eslint-disable-next-line prefer-rest-params
  error.apply(console, arguments);
};

const trackingIdCookieConfig = COOKIES_CONFIG.TRACKING_ID;
const trackingId = cookies[trackingIdCookieConfig.name] || uuidv1();

const isStagingOrProd = env === ENVIRONMENTS.PRODUCTION || env === ENVIRONMENTS.STAGING;

const accessibilityPreferencesSnakeCase = {
  theme_preference_str: accessibilityPreferences.themePreference,
  language_preference_str: accessibilityPreferences.languagePreference,
  reduced_motion_preference_bool: accessibilityPreferences.reducedMotionPreference,
  is_high_contrast_mode_preference_bool: accessibilityPreferences.isHighContrastModePreference,
};

FullStory.init({ orgId: '19BARM', devMode: !isStagingOrProd });
FullStory.event('session_started', {
  env_str: env,
  tracking_id_str: trackingId,
  ...accessibilityPreferencesSnakeCase,
});

const providers = {
  client,
  octaneClient,
};

(async () => {
  const persistConfig = {
    key: 'rootPersist',
    storage,
    whitelist: [HOMEOWNER_ONBARDING_LEADRS_REDUCER],
  };

  const reducers = createReducers(history);
  const combinedReducers = combineReducers({
    ...reducers,
  });

  const rootReducer = persistReducer(persistConfig, combinedReducers);

  // Needed when redirecting from the next project to a place where we need to pass address
  // and keep user session even if passwordless.
  const preloadedHomeownerOnboardingLeads = {};
  try {
    const addressFromNext = localStorage.getItem('address-from-next');

    if (addressFromNext) {
      const { coordinates, ...parsedAddress } = JSON.parse(addressFromNext);

      preloadedHomeownerOnboardingLeads.addresses = [{ ...parsedAddress, ...coordinates }];

      localStorage.removeItem('address-from-next');
    }
  } catch (e) {
    console.log(e);
  }

  const prelodadedData = {
    homeownerOnboardingLeads: preloadedHomeownerOnboardingLeads,
  };

  const store = createStore({
    rootReducer,
    history,
    helpers: providers,
    data: prelodadedData,
  });

  persistStore(store);

  const { dispatch, getState } = store;

  if (jwtToken) {
    client.setJwtToken(jwtToken);
    octaneClient.setJwtToken(jwtToken);

    await dispatch(fetchUser());
  }

  const partnerKey = cookies[COOKIES_CONFIG.PARTNER_KEY.name];
  client.setTrackingId(trackingId);
  octaneClient.setTrackingId(trackingId);
  trackSiteVisit(trackingId, jwtToken, partnerKey).catch((err) => console.error(err));

  let experimentsFromCookie = null;
  try {
    const experimentsConfig = COOKIES_CONFIG.EXPERIMENTS;
    const experimentsString = cookies[experimentsConfig.name];
    const experimentsParsed = experimentsString ? JSON.parse(experimentsString) : undefined;
    if (!isEmpty(experimentsParsed)) {
      experimentsFromCookie = experimentsParsed;
    }
  } catch (e) {
    console.log(e);
  }

  const experiments = experimentsFromCookie || selectExperiments(getState());
  dispatch(updateExperiments(experiments));
  pageview(window.location.pathname + window.location.search);

  const currentUser = selectUser(getState());
  if (currentUser?.userId) {
    window.mixpanel?.identify?.(currentUser.userId);
    window.mixpanel?.register?.({
      email: currentUser.email,
    });

    FullStory.identify(currentUser.userId, {
      email: currentUser.email,
    });

    datadogRum.setUser({
      id: currentUser.userId,
      email: currentUser.email,
    });

    setEnhancedConvertionData(currentUser);
  }

  const sdkConfig = {
    core: {
      authorizationKey: config.splitioApiKey,
      key: 'dummy',
    },
    features: {
      test_feature: 'on',
    },
  };

  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    <SplitFactoryProvider config={sdkConfig}>
      <Provider store={store} {...providers}>
        <AnalyticsProvider>
          <TooltipProvider delayDuration={50} skipDelayDuration={150}>
            <ConnectedRouter history={history}>
              <ApiClientProvider apiClient={client}>
                <ReduxAsyncConnect>
                  <ThemeProvider theme={theme}>
                    <App>
                      <GoogleAnalytics />
                      <GoogleTagManager />
                      <RedditPixel />
                    </App>
                  </ThemeProvider>
                </ReduxAsyncConnect>
              </ApiClientProvider>
            </ConnectedRouter>
          </TooltipProvider>
        </AnalyticsProvider>
      </Provider>
    </SplitFactoryProvider>
  );
})();
