import { ACTIONS as USER_ACTIONS } from '../user/constants';
import { ACTIONS } from './constants';
import { mockFlow } from './flow-mock';

export const HOMEOWNER_ONBARDING_REDUCER = 'homeownerOnboarding';

const initialState = {
  properties: null,
  maintenancePreferences: null,
  times: [],
  onboardingInfo: {},
  preQualificationSignUpStepByUnitUniqueId: {},
  preQualificationSignUpStepCallMeNowStatusByUnitUniqueId: {},
};

const homeownerOnboardingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.CREATE_PROPERTIES_SUCCESS:
      return {
        ...state,
        properties: [...action.result.properties],
      };

    case ACTIONS.FETCH_ONBOARDING_PROPERTIES_SUCCESS: {
      const newState = {
        ...state,
        properties: [...action.result.properties],
        signUpFlow: mockFlow,
      };

      if (action.result.maintenancePreferences) {
        newState.maintenancePreferences = [...action.result.maintenancePreferences];
      }

      if (action.result.onBoardingAppointment) {
        newState.onBoardingAppointment = { ...action.result.onBoardingAppointment };
      }

      return newState;
    }
    case ACTIONS.FETCH_ONBOARDING_INFO_SUCCESS: {
      return {
        ...state,
        onboardingInfo: { ...action.result },
      };
    }

    case ACTIONS.FETCH_CALL_TIMES_SUCCESS:
      return {
        ...state,
        times: [...action.result],
      };
    case ACTIONS.FETCH_PRE_QUALIFICATION_SIGN_UP_STEP_SUCCESS: {
      const unitUniqueId = action.result?.unitOccupancy?.uniqueId;

      return {
        ...state,
        preQualificationSignUpStepByUnitUniqueId: {
          ...state.preQualificationSignUpStepByUnitUniqueId,
          [unitUniqueId]: { ...action.result },
        },
      };
    }
    case ACTIONS.FETCH_PRE_QUALIFICATION_SIGN_UP_STEP_CALL_ME_NOW_STATUS_SUCCESS: {
      const unitUniqueId = action.result?.uniqueId;

      return {
        ...state,
        preQualificationSignUpStepCallMeNowStatusByUnitUniqueId: {
          ...state.preQualificationSignUpStepCallMeNowStatusByUnitUniqueId,
          [unitUniqueId]: { ...action.result },
        },
      };
    }
    case USER_ACTIONS.USER_DATA_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default homeownerOnboardingReducer;
