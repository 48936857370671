import { useContext, useEffect, useRef, useState } from 'react';
import { IconInfo } from '@belong/icons';
import { MoneyRange, Money } from '@belong/ui';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import Label from 'components/Label/Label';
import Tooltip from 'components/Tooltip/Tooltip';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { Box, Flex, Text } from 'design-system';
import { filter, startCase } from 'es-toolkit/compat';
import { ConsentStatus, MaintenanceAssessment, MaintenanceBundleType, MaintenanceResponsibility } from 'models/enums';
import { PreventativeBundleProgress } from 'pages/PostInspectionFlow/steps/Improvements/Approval/Bundle/PreventativeBundleProgress/PreventativeBundleProgress';
import { VacancyReductionBundleProgress } from 'pages/PostInspectionFlow/steps/Improvements/Approval/Bundle/VacancyReductionBundleProgress/VacancyReductionBundleProgress';
import {
  BUNDLE_CONFIG,
  bundleTypeDescription,
  getPaymentBreakdown,
  getPayments,
  isTBD,
} from 'pages/PostInspectionFlow/steps/Improvements/utils';
import { groupRecommendedImprovements } from 'pages/PostInspectionFlow/utils';
import PropTypes from 'prop-types';
import { getString } from 'strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import { pluralizeWithoutValue } from 'utils/pluralize';
import { ResidentResponsibilityLabel, SharedResponsibilityLabel } from '../../../../Labels/Labels';
import TwoColumnLayout from '../../Components/TwoColumnLayout/TwoColumnLayout';
import { ApprovalContext } from '../ApprovalContext';
import styles from './Bundle.module.css';
import { BundleItem } from './BundleItem/BundleItem';
import BundlePrice, { StrikedPrice } from './BundlePrice/BundlePrice';

const cx = classNames.bind(styles);

const propTypes = {
  bundle: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isMonthly: PropTypes.bool,
  isGTTLDisqualifiedDueHomeownerFixingItems: PropTypes.bool,
  inspectionType: PropTypes.object,
  displayPrice: PropTypes.bool,
  isDoNotExceedGroup: PropTypes.bool,
  improvementPresets: PropTypes.array,
};

const defaultProps = {};

const Bundle = ({
  bundle,
  onChange,
  onClick,
  onToggle,
  open,
  isMonthly,
  inspectionType,
  isGTTLDisqualifiedDueHomeownerFixingItems,
  displayPrice,
  isDoNotExceedGroup,
  improvementPresets,
}) => {
  const approvalContext = useContext(ApprovalContext);
  const content = useRef(null);
  const orderedBundleItems = useRef(null);
  const breakdown = getPaymentBreakdown(bundle);

  const estimatedCost = breakdown.homeowner?.estimatedCost || 0;
  const residentEstimatedCost = breakdown.resident?.estimatedCost || 0;
  const residentResponsiblity = estimatedCost === 0 && residentEstimatedCost !== 0;
  const sharedResponsiblity = estimatedCost !== 0 && residentEstimatedCost !== 0;
  const { type, displayAsPriceRange } = bundle;
  const bundleConfig = BUNDLE_CONFIG[type];
  const { maximumMonthlyPaymentPlan, homeownerPayment } = approvalContext;

  const homeownerItems = filter(
    bundle.items,
    (item) =>
      !!item.maintenancePayments.find(
        (payment) => payment.paidBy === MaintenanceResponsibility.Homeowner && payment.percentage !== 0
      )
  );

  const isRequired = homeownerItems.every(
    (homeownerItem) => homeownerItem.maintenance.assessment === MaintenanceAssessment.Required
  );

  const isPartiallyRequired = homeownerItems.find(
    (homeownerItem) => homeownerItem.maintenance.assessment === MaintenanceAssessment.Required
  );

  const isRecommended = homeownerItems.every(
    (homeownerItem) => homeownerItem.maintenance.assessment === MaintenanceAssessment.Recommended
  );

  const approvedItems = filter(bundle.items, (item) => {
    const paymentBreakdown = getPayments(item.maintenancePayments);

    if (paymentBreakdown.resident?.percentage === 100) return true;
    if (!paymentBreakdown.homeowner) return false;
    if (paymentBreakdown.homeowner.percentage === 0) {
      return true;
    }

    return paymentBreakdown.homeowner.consentStatus === ConsentStatus.Approved;
  });

  let label;

  if (residentResponsiblity) {
    label = <ResidentResponsibilityLabel />;
  } else if (sharedResponsiblity) {
    label = <SharedResponsibilityLabel />;
  } else if (isRequired) {
    label = <Label text="Required" />;
  } else if (isPartiallyRequired) {
    label = <Label text="Partially Required" />;
  } else if (isRecommended) {
    label = <Label text="Recommended" />;
  }

  const bundlePercentage = (approvedItems.length / homeownerItems.length) * 100;

  const isPreventative = type === MaintenanceBundleType.Preventative;
  const isVacancyReduction = type === MaintenanceBundleType.VacancyReduction;

  const showVacancyReductionProgress = isVacancyReduction && bundle.items.length > 1;

  const totalEstimatedCostPrevented = bundle.items.reduce((totalCost, currentItem) => {
    const currentItemCost = currentItem.maintenance.estimatedCostPrevented || 0;

    return totalCost + currentItemCost;
  }, 0);

  const getBundleReasonTitle = () => {
    const pronoun = pluralizeWithoutValue(bundle.items.length, 'these');
    const capitalizedPronoun = startCase(pronoun);

    const subject = pluralizeWithoutValue(bundle.items.length, 'improvement');
    const capitalizedSubject = startCase(subject);

    const verbPluralizeKey = isRequired ? 'is' : 'matter';
    const verb = pluralizeWithoutValue(bundle.items.length, verbPluralizeKey);
    const capitalizedVerb = startCase(verb);

    const titleStringKey = isRequired
      ? 'approvals.importance.bundle.title.required'
      : 'approvals.importance.bundle.title.matter';

    return getString(POST_INSPECTION_FLOW_STRINGS[titleStringKey], {
      pronoun: capitalizedPronoun,
      subject: capitalizedSubject,
      verb: capitalizedVerb,
    });
  };

  const [overflowAuto, setOverflowAuto] = useState(!open);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOverflowAuto(false);
      }, [100]);

      return;
    }

    setOverflowAuto(true);
  }, [open]);

  useEffect(() => {
    // We only sort the items from the cheapest to most expensive on the first page load.
    // Since we don't have an itemId, we use the maintenance jobId as lookup key.
    if (!orderedBundleItems.current) {
      orderedBundleItems.current = [...bundle.items]
        .sort((itemA, itemB) => {
          // TBD items go at the very bottom
          if (itemA.isTBD) {
            return 1;
          }

          return itemA.estimatedDisplayCost > itemB.estimatedDisplayCost ? 1 : -1;
        })
        .map((item) => item.maintenance.jobId);
    }
  }, [bundle.items]);

  // If the items were previously ordered, we'll preserve that no matter if the user chooses an option which causes
  // the improvement be in a different position. For that is that we have stored the maintenance jobId in a ref.
  const bundleItemsWithInitialOrder = orderedBundleItems.current
    ? orderedBundleItems.current.map((jobId) => {
        const bundlefilter = bundle.items.find((item) => item.maintenance.jobId === jobId);

        return bundlefilter;
      })
    : bundle.items;

  return (
    <div
      className={cx('bundle', {
        'open-borders': open && bundlePercentage < 100,
        open,
      })}
    >
      <ButtonBase className={cx('bundle-header')} onClick={() => onToggle(type)}>
        <div className={cx('top-section')}>
          <div className={cx('left-section')}>
            <div className={cx('bundle-details')}>
              <Flex
                alignItems="flex-start"
                flexDirection="column"
                justifyContent={['unset', 'space-between']}
                marginRight="xs"
              >
                {!groupRecommendedImprovements && (
                  <>
                    {label && (
                      <Flex justifyContent="flex-start" marginBottom={['xs', '2xs']}>
                        {label}
                      </Flex>
                    )}
                  </>
                )}
                <Text className={cx('initial')} fontWeight="semibold">
                  {bundleConfig.displayName} ({approvedItems.length})
                </Text>
                <p className="p1 text-green font-semibold">0% Belong Markup</p>
              </Flex>
            </div>
          </div>
          <div className={cx('right-section')}>
            {displayPrice && (
              <>
                <div className={cx('price')}>
                  {displayAsPriceRange ? (
                    <MoneyRange
                      lower={bundle.priceRange?.lowerBoundSubtotal}
                      upper={bundle.priceRange?.upperBoundSubtotal}
                      className="font-semibold"
                      format="DOLLARS_NO_CENTS"
                    />
                  ) : (
                    <>
                      {!residentResponsiblity && (
                        <BundlePrice
                          maximumMonthlyPaymentPlan={maximumMonthlyPaymentPlan}
                          homeownerPayment={homeownerPayment}
                          bundle={bundle}
                          textClassname={cx('text')}
                          isMonthly={isMonthly}
                        />
                      )}
                      {residentResponsiblity && (
                        <div className={cx('gray')}>
                          <Money value={bundle.estimatedCost} format="DOLLARS_NO_CENTS" />
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div>
                  {displayAsPriceRange && (
                    <Tooltip
                      contentStyle={{ width: 330, backgroundColor: '#000', padding: '12px' }}
                      trigger={
                        <div className="mr-xs -ml-xs cursor-pointer hidden md:block" style={{ marginTop: '5px' }}>
                          <IconInfo width={20} />
                        </div>
                      }
                    >
                      <p className="p1 text-white">
                        We&apos;ve calculated a repair range factoring in potential scope changes that can affect the
                        final cost once a contractor takes a closer look. Belong will negotiate the lowest final price
                        with no mark-up. Greenlighting this range empowers our team to start work promptly.
                      </p>
                    </Tooltip>
                  )}
                </div>
              </>
            )}

            <div className={cx('caret-icon')}>
              <GeneralIcon icon={GENERAL_ICONS.DOWN_CARET} />
            </div>
          </div>
        </div>

        {displayPrice && (
          <div className={cx('bottom-section')}>
            {displayAsPriceRange ? (
              <MoneyRange
                lower={bundle.priceRange?.lowerBoundSubtotal}
                upper={bundle.priceRange?.upperBoundSubtotal}
                className="font-semibold"
                format="DOLLARS_NO_CENTS"
              />
            ) : (
              <>
                {!!approvedItems.length && (
                  <BundlePrice
                    bullet
                    maximumMonthlyPaymentPlan={maximumMonthlyPaymentPlan}
                    homeownerPayment={homeownerPayment}
                    bundle={bundle}
                    textClassname={cx('text')}
                  />
                )}
              </>
            )}
            {displayAsPriceRange && (
              <Tooltip
                contentStyle={{ width: 260, backgroundColor: '#000', padding: '12px' }}
                trigger={
                  <div className="ml-xs cursor-pointer block md:hidden" style={{ marginTop: '5px' }}>
                    <IconInfo width={20} />
                  </div>
                }
              >
                <p className="p1 text-white">
                  We&apos;ve calculated a repair range factoring in potential scope changes that can affect the final
                  cost once a contractor takes a closer look. Belong will negotiate the lowest final price with no
                  mark-up. Greenlighting this range empowers our team to start work promptly.
                </p>
              </Tooltip>
            )}
          </div>
        )}

        {!groupRecommendedImprovements && (
          <>
            {isPreventative && !!totalEstimatedCostPrevented && (
              <PreventativeBundleProgress
                approvedItems={approvedItems}
                totalEstimatedCostPrevented={totalEstimatedCostPrevented}
              />
            )}
            {showVacancyReductionProgress && (
              <VacancyReductionBundleProgress approvedItems={approvedItems} bundle={bundle} />
            )}
          </>
        )}
      </ButtonBase>
      <div
        ref={content}
        className={cx('content', { overflowAuto: !open || overflowAuto })}
        style={{ maxHeight: open ? content.current.scrollHeight : '0px' }}
      >
        <div className={cx('dashed-separator')} />

        <Flex className={cx('why-reason')} flexDirection="column">
          <div className="mb-2xs mt-md">
            <Text fontWeight="semibold">{getBundleReasonTitle()}</Text>
          </div>
          <Text>{bundleTypeDescription[type]}</Text>
        </Flex>
        <div className={cx(['items-container', bundlePercentage === 100 && 'selected-item'])}>
          <TwoColumnLayout
            items={bundleItemsWithInitialOrder.map((item) => (
              <BundleItem
                key={item.maintenance.id}
                item={item}
                isPreventative={isPreventative}
                onChange={onChange}
                onClick={() => onClick(item)}
                inspectionType={inspectionType}
                isGTTLDisqualifiedDueHomeownerFixingItems={isGTTLDisqualifiedDueHomeownerFixingItems}
                isDoNotExceedGroup={isDoNotExceedGroup}
                improvementPresets={improvementPresets}
                price={(selected) => {
                  const {
                    costOptions,
                    maintenance: { proServiceResponsibility },
                  } = item;

                  if (proServiceResponsibility === MaintenanceResponsibility.Homeowner) {
                    return null;
                  }

                  const range = item.displayAsPriceRange
                    ? {
                        lowerBound: item.priceRange?.lowerBoundSubtotal,
                        upperBound: item.priceRange?.upperBoundSubtotal,
                      }
                    : null;

                  return (
                    <Flex alignItems="flex-end" flexDirection="column">
                      {displayPrice && (
                        <Box>
                          <StrikedPrice
                            item={item}
                            textProps={{
                              color: 'white',
                              ...(isTBD(item) && {
                                fontWeight: 'semibold',
                              }),
                            }}
                            selected={selected}
                            isMonthly={isMonthly}
                            months={maximumMonthlyPaymentPlan}
                            range={range}
                            showFullPrice
                          />
                        </Box>
                      )}
                      {costOptions?.length > 1 && (
                        <Box>
                          <Text color="white" fontSize="p1" fontWeight="semibold">
                            {costOptions.length} options available
                          </Text>
                        </Box>
                      )}
                    </Flex>
                  );
                }}
              />
            ))}
            padding={20}
          />
        </div>
      </div>
    </div>
  );
};

Bundle.propTypes = propTypes;
Bundle.defaultProps = defaultProps;

export default Bundle;
