import { isToday, isTomorrow, parse, isWeekend as isWeekend$1, addDays } from 'date-fns';
import { formatDateTime } from './format-date.js';

function futureUnixTimestamp(days) {
  // Get the current date
  var date = new Date();
  // Add the number of days to the current date
  date.setDate(date.getDate() + days);
  // Return the Unix timestamp in seconds
  return Math.floor(date.getTime() / 1000);
}
function getDescriptiveDay(dateTime) {
  var descriptiveDay = '';
  if (isToday(dateTime)) {
    descriptiveDay += 'Today, ';
  }
  if (isTomorrow(dateTime)) {
    descriptiveDay += 'Tomorrow, ';
  }
  descriptiveDay += formatDateTime({
    dateTime: dateTime,
    format: 'iiii, MMMM d'
  });
  return descriptiveDay;
}
// Gets Monday of the previous week
function getPreviousWeekMonday() {
  var today = new Date();
  var dayOfWeek = today.getDay();
  var difference = today.getDate() - dayOfWeek - 6;
  var monday = new Date(today.setDate(difference));
  monday.setHours(0, 0, 0, 0);
  return monday;
}
// Gets Sunday of the previous week
function getPreviousWeekSunday() {
  var previousMonday = getPreviousWeekMonday();
  var sunday = new Date(previousMonday);
  sunday.setDate(previousMonday.getDate() + 6);
  sunday.setHours(23, 59, 59, 999);
  return sunday;
}
function getNumberOfDaysFromTimeStamp(timeStamp) {
  if (!timeStamp) {
    return 0;
  }
  var date = new Date(timeStamp);
  var now = new Date();
  var diff = now.getTime() - date.getTime();
  return Math.ceil(diff / (1000 * 60 * 60 * 24));
}
/**
 * Check if a string date is weekend day
 * @param dateString 'MM/dd/yyyy'
 * @returns
 */
function isWeekend(dateString) {
  var format = 'MM/dd/yyyy';
  var parsedDate = parse(dateString, format, new Date());
  return isWeekend$1(parsedDate);
}
/**
 * Add days to a date string
 * @param dateStr 'MM/dd/yyyy'
 * @param days number of days to add
 * @returns 'MM/dd/yyyy'
 * @example addDaysHelper('01/01/2021', 1) => '01/02/2021'
 */
function addDaysHelper(dateStr, days) {
  // Parse the input date string
  var parsedDate = parse(dateStr, 'MM/dd/yyyy', new Date());
  // Add the specified number of days
  var newDate = addDays(parsedDate, days);
  // Format the new date back to 'MM/DD/yyyy'
  return newDate.toLocaleDateString('en-US');
}

export { addDaysHelper, futureUnixTimestamp, getDescriptiveDay, getNumberOfDaysFromTimeStamp, getPreviousWeekMonday, getPreviousWeekSunday, isWeekend };
