import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import { UserProfileBannerStep } from 'accounts/components/user-profile-banner-step';
import { STEP_GATE_NAMES } from 'accounts/constants/steps';
import { USER_PROFILE_STRINGS } from 'accounts/constants/strings/user-profile.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import NewMediaUploader, { MEDIA_LABEL } from 'components/NewMediaUploader/NewMediaUploader';
import { isNil } from 'es-toolkit/compat';
import { Row, Col } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { StepStatus } from 'models/enums';
import { uploadProfilePhoto } from 'store/redux/user/actions';

export function UserProfile() {
  const dispatch = useDispatch();
  const [mediaObj, setMedia] = useState<any>({ availableMedia: [] });

  const { currentStepFormData, currentStep, properties, propertySection, handleSaveAndNext, selectedProperty } =
    useSetupFlowStep();

  const title =
    currentStep?.status === StepStatus.Started && properties?.length > 1
      ? USER_PROFILE_STRINGS['title.populated']
      : USER_PROFILE_STRINGS.title;
  const isAdoptedResident = selectedProperty?.units?.some((unit) => unit.isAdoptedAgreement);

  function handleMedia(file) {
    setMedia(file);
  }

  async function handleSubmit(values) {
    const submitValues = { ...values };

    if (mediaObj.stagingMedia?.length || mediaObj.deletedMedia?.length) {
      try {
        const uploadedMediaId = await dispatch(uploadProfilePhoto(mediaObj));
        if (!isNil(uploadedMediaId)) submitValues.profileImageId = uploadedMediaId;
      } catch (err) {
        console.error(err);
      }
    } else if (mediaObj.availableMedia?.length) {
      submitValues.profileImageId = mediaObj.availableMedia[0].profileImageId;
    }

    await handleSaveAndNext({
      userProfileDetailsModel: submitValues,
    });
  }

  function getInitialValues() {
    const initialValues = {
      hometown: currentStepFormData?.userProfileDetailsModel?.hometown || '',
      aboutMe: currentStepFormData?.userProfileDetailsModel?.aboutMe || '',
    };

    return { ...initialValues };
  }

  function getBannerStep() {
    const buildListingIsCompleted = propertySection.every((section) => {
      const buildListingSection = section?.subSections?.find(
        (subSection) => subSection.name === STEP_GATE_NAMES.BuildListing
      );

      return buildListingSection.isCompleted;
    });

    if (propertySection.length && buildListingIsCompleted) {
      return <UserProfileBannerStep isAdoptedResident={isAdoptedResident} />;
    }

    return undefined;
  }

  useEffect(() => {
    const media = {
      url: currentStepFormData?.userProfileDetailsModel?.profileImageUrl,
      profileImageId: currentStepFormData?.userProfileDetailsModel?.profileImageId,
    };
    if (media.url && media.profileImageId) {
      setMedia({ availableMedia: [media] });
    }
  }, [currentStepFormData]);

  return (
    <StepFormLayout
      onSubmit={handleSubmit}
      title={title}
      initialValues={getInitialValues()}
      showUnitAddress={false}
      bannerStep={getBannerStep()}
      getForm={() => (
        <div className="pt-sm">
          <FormLayout.Section>
            <NewMediaUploader
              availableMedia={mediaObj.availableMedia}
              handleMedia={handleMedia}
              mediaLabel={MEDIA_LABEL.SELFIE}
              isSingleMedia
            />
            <Row>
              <Col md={6}>
                <Field name="hometown" component={InputFinalFormAdapter} placeholder={USER_PROFILE_STRINGS.hometown} />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Field
                  name="aboutMe"
                  component={InputFinalFormAdapter}
                  placeholder={USER_PROFILE_STRINGS.about_me}
                  textarea
                />
              </Col>
            </Row>
          </FormLayout.Section>
        </div>
      )}
    />
  );
}
