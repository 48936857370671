import React, { Component, Fragment } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import classNames from 'classnames/bind';
import { CheckboxCard } from 'components/CheckboxCard/CheckboxCard';
import GeneralIcon, { GENERAL_ICONS, COLORS } from 'components/GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
import { isNil } from 'es-toolkit/compat';
import Condition from 'formcomponents/Condition/Condition';
import PropTypes from 'prop-types';
import { formatString } from 'strings';
import String from '../String/String';
import styles from './SelectableFormPanel.module.css';

const cx = classNames.bind(styles);

export const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
};

export const SelectableContainer = ({
  children,
  selected,
  disabled,
  disableCursor,
  error,
  multiple,
  size,
  onClick,
}) => {
  return (
    <div
      className={cx(
        'checkbox-selector-with-form-wrapper',
        { selected },
        { disabled },
        { 'disable-cursor': disableCursor },
        { error },
        { multiple },
        { size }
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

SelectableContainer.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  disableCursor: PropTypes.bool,
  error: PropTypes.bool,
  multiple: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZES)),
  onClick: PropTypes.func,
};

SelectableContainer.defaultProps = {
  selected: false,
  disabled: false,
  disableCursor: false,
  error: false,
  multiple: false,
  size: SIZES.MEDIUM,
  onClick: () => {},
};

export const SelectableHeader = (props) => {
  const { name, title, selected, showTrashButton, showCheckmark, onTrashClick, disabled, showPlus } = props;

  return (
    <div className={cx('heading-section')}>
      {name && <OnChange name={`${name}.selected`}>{() => {}}</OnChange>}
      <div className={cx('title', { disabled }, { selected }, { showPlus })}>
        {showCheckmark && (
          <GeneralIcon
            icon={selected ? GENERAL_ICONS.CHECKBOX_SELECTED : GENERAL_ICONS.CHECKBOX_UNSELECTED}
            color={selected ? COLORS.DARK_NAVY : COLORS.GRAY}
          />
        )}
        {showPlus && <span className={cx('show-icon')} />}
        <div className={cx('text', { hasIcon: showCheckmark || showPlus })}>
          <span>{formatString(title)}</span>
        </div>
      </div>
      {showTrashButton && (
        <IconButton
          align={ALIGN_TYPES.LEFT}
          icon={GENERAL_ICONS.TRASH}
          onClick={(e) => {
            e.stopPropagation();
            onTrashClick();
          }}
        />
      )}
    </div>
  );
};

SelectableHeader.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  showTrashButton: PropTypes.bool,
  showCheckmark: PropTypes.bool,
  onTrashClick: PropTypes.func,
  disabled: PropTypes.bool,
  showPlus: PropTypes.bool,
};

SelectableHeader.defaultProps = {
  name: null,
  selected: false,
  showTrashButton: false,
  showCheckmark: false,
  disabled: false,
  showPlus: false,
  onTrashClick: () => {},
};

export default class SelectableFormPanel extends Component {
  returnAllOtherProperties = (obj, properties) => {
    return typeof obj === 'object'
      ? Object.keys(obj).filter((property) => !properties.includes(property) && obj[property] !== null)
      : [];
  };

  render() {
    const {
      name,
      disabled,
      formFields,
      allowMultiple,
      addMoreTitle,
      form,
      error,
      size,
      title,
      restricted,
      getDefaultValue,
      additionalSubTitle,
      showCheckmark,
      noBorder,
      showPlus,
      clearOnDeselect,
      showTrashButton: showTrashButtonProp,
      ...rest
    } = this.props;

    const currentValue = form.getFieldState(name)?.value;

    if (allowMultiple) {
      return (
        <Fragment>
          <FieldArray name={name}>
            {({ fields }) =>
              fields.map((fieldArrayName, fieldIndex) => {
                const currentIndexValue = Array.isArray(currentValue) && { ...currentValue[fieldIndex] };
                const selectedValue = currentIndexValue?.selected;
                const hasFormFields = !isNil(formFields);
                // const allOtherProperties = this.returnAllOtherProperties(currentIndexValue, ['selected', 'name']);
                const showTrashButton = showTrashButtonProp && selectedValue && !restricted;

                let clearCallback;
                if (fieldIndex === 0) {
                  // hasFormValues = hasFormFields && allOtherProperties.length > 0;
                  clearCallback = () =>
                    form.mutators.update(name, 0, {
                      ...(getDefaultValue ? getDefaultValue(0) : {}),
                      selected: false,
                    });
                } else {
                  // hasFormValues = true;
                  clearCallback = () => {
                    form.mutators.remove(name, fieldIndex);
                  };
                }

                const disableHeaderAction = disabled;

                return (
                  <div
                    className={cx({ 'mb-sm': fields.length > 1 && fieldIndex + 1 !== fields.length })}
                    key={fieldArrayName}
                  >
                    <CheckboxCard
                      name={fieldArrayName}
                      title={currentIndexValue?.title || title}
                      selected={!!selectedValue}
                      disabled={disabled}
                      error={error}
                      showTrashButton={showTrashButton}
                      showCheckmark={showCheckmark}
                      noBorder={noBorder}
                      showPlus={showPlus}
                      onTrashClick={clearCallback}
                      cursorDisabled={showTrashButtonProp && disableHeaderAction}
                      size={size}
                      onClick={() => {
                        if (!disableHeaderAction) {
                          const newValue = !form.getFieldState(`${fieldArrayName}.selected`).value;

                          form.change(`${fieldArrayName}.selected`, newValue);

                          // No context on why we did this, but it breaks components that don't have trashcan on close.
                          if (!newValue && clearOnDeselect) {
                            clearCallback();
                          }
                        }
                      }}
                      {...rest}
                    >
                      <Condition when={`${fieldArrayName}.selected`} is>
                        {hasFormFields && (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            {React.cloneElement(formFields, {
                              name: fieldArrayName,
                              nestedField: true,
                              arrayFieldName: name,
                              index: fieldIndex,
                              form,
                              disabled,
                              ...rest,
                            })}
                          </div>
                        )}
                      </Condition>
                      {selectedValue && !restricted && (
                        <div className={cx('bottom-controls')} onClick={(event) => event.stopPropagation}>
                          <div className={cx('additional-sub-title')}>{additionalSubTitle}</div>
                          <IconButton
                            align={ALIGN_TYPES.RIGHT}
                            icon={GENERAL_ICONS.PLUS}
                            onClick={(event) => {
                              event.stopPropagation();

                              form.mutators.push(name, {
                                ...(getDefaultValue ? getDefaultValue(currentValue.length) : {}),
                                selected: true,
                              });
                            }}
                          >
                            <String string={addMoreTitle || title} />
                          </IconButton>
                        </div>
                      )}
                    </CheckboxCard>
                  </div>
                );
              })
            }
          </FieldArray>
        </Fragment>
      );
    }

    const selectedValue = currentValue?.selected;
    const hasFormFields = !isNil(formFields);
    const allOtherProperties = this.returnAllOtherProperties(currentValue, ['selected']);
    // const hasFormValues = hasFormFields && allOtherProperties.length > 0;

    const showTrashButton = showTrashButtonProp && hasFormFields && selectedValue && !restricted;
    const disableHeaderAction = showTrashButtonProp && (disabled || (hasFormFields && selectedValue));

    const clearCallback = () =>
      form.batch(() => {
        allOtherProperties.forEach((property) => {
          form.change(`${name}.${property}`, null);
        });
        form.change(`${name}.selected`, false);
      });

    return (
      <CheckboxCard
        name={name}
        title={title}
        selected={!!selectedValue}
        disabled={disabled}
        error={error}
        showTrashButton={showTrashButton}
        showCheckmark={showCheckmark}
        noBorder={noBorder}
        showPlus={showPlus}
        onTrashClick={clearCallback}
        cursorDisabled={disableHeaderAction}
        size={size}
        onClick={() => {
          if (!disableHeaderAction) {
            form.change(`${name}.selected`, !form.getFieldState(`${name}.selected`).value);
          }
        }}
        {...rest}
      >
        <Condition when={`${name}.selected`} is>
          {hasFormFields && (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {React.cloneElement(formFields, { name, form, disabled, ...rest })}
            </div>
          )}
        </Condition>
      </CheckboxCard>
    );
  }
}

SelectableFormPanel.propTypes = {
  name: PropTypes.string,
  formFields: PropTypes.node,
  allowMultiple: PropTypes.bool,
  form: PropTypes.object,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  showCheckmark: PropTypes.bool,
  showTrashButton: PropTypes.bool,
  showPlus: PropTypes.bool,
  noBorder: PropTypes.bool,
  restricted: PropTypes.bool,
  getDefaultValue: PropTypes.func,
  additionalSubTitle: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  addMoreTitle: PropTypes.string,
  clearOnDeselect: PropTypes.bool,
};

SelectableFormPanel.defaultProps = {
  name: '',
  title: '',
  formFields: null,
  allowMultiple: false,
  form: {},
  disabled: false,
  error: false,
  size: SIZES.MEDIUM,
  showCheckmark: false,
  restricted: false,
  showPlus: false,
  getDefaultValue: null,
  additionalSubTitle: '',
  showTrashButton: true,
  clearOnDeselect: true,
};

// eslint-disable-next-line react/prop-types
export const SelectableFormPanelFinalFormAdapter = ({ input, ...rest }) => <SelectableFormPanel {...input} {...rest} />;
