import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { Box, Flex, Text } from 'design-system';
import { flatten } from 'es-toolkit/compat';
import { MaintenanceResponsibility } from 'models/enums';
import PropTypes from 'prop-types';
import TwoColumnLayout from '../../Components/TwoColumnLayout/TwoColumnLayout';
import { isTBD } from '../../utils';
import { BundleItem } from '../Bundle/BundleItem/BundleItem';
import { StrikedPrice } from '../Bundle/BundlePrice/BundlePrice';
import styles from './Recommended.module.css';

const cx = classNames.bind(styles);

const propTypes = {
  bundles: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  showPrice: PropTypes.bool,
};

export const Recommended = ({ bundles, onChange, onClick, showPrice }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const orderedBundleItems = useRef(null);

  const items = flatten(
    bundles.map((bundle) => {
      const item = bundle.items[0];

      return {
        ...item,
        bundle,
      };
    })
  );

  // If the items were previously ordered, we'll preserve that no matter if the user chooses an option which causes
  // the improvement be in a different position. For that is that we have stored the maintenance jobId in a ref.
  const bundleItemsWithInitialOrder = orderedBundleItems.current
    ? orderedBundleItems.current.map((jobId) => {
        const bundlefilter = items.find((item) => item.maintenance.jobId === jobId);

        return bundlefilter;
      })
    : [...items];

  const prioritizedItems = bundleItemsWithInitialOrder.filter(Boolean).slice(0, 2);

  const itemsToShow = isExpanded ? bundleItemsWithInitialOrder : prioritizedItems;

  const isShowMoreSectionVisible = items.length > 2;

  const handleShowMoreClick = () => {
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  };

  // We could move this to a custom hook since it's the same logic in the required bundles.
  // Not worth it until we move this to a better folder structure, such as setup-flow.
  useEffect(() => {
    // We only sort the items from the cheapest to most expensive on the first page load.
    // Since we don't have an itemId, we use the maintenance jobId as lookup key.
    if (!orderedBundleItems.current) {
      orderedBundleItems.current = [...items]
        .sort((itemA, itemB) => {
          // TBD items go at the very bottom
          if (itemA.isTBD) {
            return 1;
          }

          return itemA.estimatedDisplayCost > itemB.estimatedDisplayCost ? 1 : -1;
        })
        .map((item) => item.maintenance.jobId);
    }
  }, [items]);

  return (
    <div className={cx('inspirational')}>
      <TwoColumnLayout
        items={itemsToShow.map((item) => {
          return (
            <BundleItem
              key={item.maintenance.id}
              item={item}
              onChange={onChange}
              onClick={() => onClick(item)}
              price={(selected) => {
                const {
                  costOptions,
                  maintenance: { proServiceResponsibility },
                } = item;

                if (proServiceResponsibility === MaintenanceResponsibility.Homeowner) {
                  return null;
                }

                const hoRange = item.priceRange?.priceRangeBreakdown?.find((range) => range.paidBy === 'Homeowner');

                const range = item.displayAsPriceRange
                  ? {
                      lowerBound: hoRange.lowerBoundSubtotal,
                      upperBound: hoRange.upperBoundSubtotal,
                    }
                  : null;

                return (
                  <Flex alignItems="flex-end" flexDirection="column">
                    {showPrice && (
                      <Box>
                        <StrikedPrice
                          item={item}
                          textProps={{
                            color: 'white',
                            ...(isTBD(item) && {
                              fontWeight: 'semibold',
                            }),
                          }}
                          selected={selected}
                          range={range}
                        />
                      </Box>
                    )}
                    {costOptions?.length > 1 && (
                      <Box>
                        <Text color="white" fontSize="p1" fontWeight="semibold">
                          {costOptions.length} options available
                        </Text>
                      </Box>
                    )}
                  </Flex>
                );
              }}
            />
          );
        })}
        padding={30}
      />
      {isShowMoreSectionVisible && (
        <Flex justifyContent="flex-end" marginTop="xs">
          <ButtonBase onClick={handleShowMoreClick}>
            <Box paddingY="xs">
              <Text fontWeight="semibold">{isExpanded ? 'SHOW LESS' : 'SHOW MORE'}</Text>
            </Box>
          </ButtonBase>
        </Flex>
      )}
    </div>
  );
};

Recommended.propTypes = propTypes;
