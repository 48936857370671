import { useContext, useState } from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { PAYMENTS_FEE_CONST } from '@belong/common';
import { Text } from '@belong/ui';
import classNames from 'classnames/bind';
import Button, { BUTTON_SIZES } from 'components/Button/Button';
import Field from 'components/Field/Field';
import Money from 'components/Money/Money';
import { SelectorFinalFormAdapter } from 'components/Selector/Selector';
import String from 'components/String/String';
import TabBar from 'components/TabBar/TabBar';
import PaymentMethodContainer from 'containers/PaymentMethodContainer/PaymentMethodContainer';
import Space from 'corecomponents/Space/Space';
import { Box, Text as LegacyText } from 'design-system';
import { flatten } from 'es-toolkit/compat';
import {
  MaintenanceResponsibility,
  PaymentMethod,
  PaymentPreference,
  PaymentPriority,
  CustomMaintenancePaymentPlan,
  MaintenanceBundleRewardType,
  MaintenancePaymentMethod,
  MaintenancePaymentPlan,
} from 'models/enums';
import { ApprovalContext } from 'pages/PostInspectionFlow/steps/Improvements/Approval/ApprovalContext';
import { getFlaggedItems } from 'pages/PostInspectionFlow/steps/Improvements/utils';
import { STRINGS } from 'post-inspection/constants/strings';
import type { HomeOwnerPayment } from 'post-inspection/models/improvements-report';
import { selectFullUser } from 'store/redux/user/selectors';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import { required } from 'utils/validation';
import styles from './legacy-payment-plan-form.module.css';

const cx = classNames.bind(styles);

// todo: migrate this to a tab component once we have it in @belong/ui
type TabItem = {
  key: string;
  text: string;
};

const tabs: TabItem[] = [
  {
    key: CustomMaintenancePaymentPlan.FromRent,
    text: STRINGS['from-rent'],
  },
  {
    key: CustomMaintenancePaymentPlan.UponCompletion,
    text: STRINGS['upon-completion'],
  },
];

type FormSchema = {
  deferPaymentUntilLease: boolean;
  installmentCount: number;
  paymentMethod: keyof typeof MaintenancePaymentMethod;
  paymentPlan: keyof typeof CustomMaintenancePaymentPlan | keyof typeof MaintenancePaymentPlan;
};

type Props = {
  bundles: { items: any[] }[];
  homeownerPayment: HomeOwnerPayment;
  rewards: any[];
};

// Not much time was allocated to migrate this component to TypeScript as we're expecting to remove legacy reports shortly.
export const LegacyPaymentPlanForm = ({ bundles, homeownerPayment, rewards }: Props) => {
  const { onApprovalStepSubmit, maximumMonthlyPaymentPlan } = useContext<any>(ApprovalContext);
  const [currentTab, setCurrentTab] = useState<keyof typeof CustomMaintenancePaymentPlan>(() => {
    if (homeownerPayment?.paymentPlan) {
      return homeownerPayment.paymentPlan === MaintenancePaymentPlan.OneTime
        ? CustomMaintenancePaymentPlan.UponCompletion
        : CustomMaintenancePaymentPlan.FromRent;
    }

    return maximumMonthlyPaymentPlan
      ? CustomMaintenancePaymentPlan.FromRent
      : CustomMaintenancePaymentPlan.UponCompletion;
  });

  const fullUser = useSelector(selectFullUser);

  const items = flatten(bundles.map((bundle) => bundle.items));
  const flaggedItems = getFlaggedItems(items, MaintenanceResponsibility.Homeowner);

  const monthlyRewards = rewards.filter((x) => x.type === MaintenanceBundleRewardType.MonthlyInstallment);

  const hasUpfrontAmount = homeownerPayment?.estimatedUpfrontAmount > 0;

  const handleTabChange = (item: TabItem) => setCurrentTab(item.key);

  const getInitialValues = (): Partial<FormSchema> => {
    const initialValues: Partial<FormSchema> = {};

    if (homeownerPayment?.paymentPlan) {
      if (homeownerPayment.paymentPlan === MaintenancePaymentPlan.Monthly) {
        initialValues.paymentPlan = CustomMaintenancePaymentPlan.FromRent;
        initialValues.installmentCount = homeownerPayment.installmentCount;
      }

      if (homeownerPayment?.paymentPlan === MaintenancePaymentPlan.OneTime) {
        initialValues.paymentPlan = CustomMaintenancePaymentPlan.UponCompletion;
        initialValues.paymentMethod = homeownerPayment.paymentMethod;
      }
    }

    if (maximumMonthlyPaymentPlan) {
      initialValues.paymentPlan = CustomMaintenancePaymentPlan.FromRent;
      initialValues.installmentCount = maximumMonthlyPaymentPlan;
    } else {
      initialValues.paymentPlan = CustomMaintenancePaymentPlan.UponCompletion;
    }

    return initialValues;
  };

  const handleSubmit = async (values) => {
    const data = {
      ...homeownerPayment,
    };

    if (currentTab === CustomMaintenancePaymentPlan.FromRent) {
      data.paymentPlan = MaintenancePaymentPlan.Monthly;
      data.installmentCount = values.installmentCount;
      data.deferPaymentUntilLease = true;
    }

    if (currentTab === CustomMaintenancePaymentPlan.UponCompletion) {
      data.paymentPlan = MaintenancePaymentPlan.OneTime; // siempre es esto ahora
      data.installmentCount = 1; // seguir mandando esto
      data.deferPaymentUntilLease = false; // tiene que ir en null
    }

    data.paymentMethod = values.paymentMethod; // check o creditcard, si no tengo nada dueNow tengo que mandar null

    onApprovalStepSubmit({ groupPayment: data });
  };

  const { estimatedInstallmentBreakdown } = homeownerPayment || {};

  return (
    <div className="mt-lg">
      <div className="mb-lg text-center">
        <Text fontWeight="semibold" variant="h3">
          {STRINGS['payment-method-question']}
        </Text>
      </div>
      <div className="h-[33px] mb-sm">
        <TabBar items={tabs} onSelect={handleTabChange} selected={currentTab} />
      </div>
      <Form<Partial<FormSchema>>
        initialValues={getInitialValues()}
        onSubmit={handleSubmit}
        render={({ handleSubmit: formSubmit, form, invalid }) => {
          const { installmentCount, paymentMethod } = form.getState().values;

          let currentMonthlyCost = 0;
          const totalCost = homeownerPayment?.estimatedRemainingAmount || 0;

          if (currentTab === CustomMaintenancePaymentPlan.FromRent) {
            const selectedInstallmentCostDetail = estimatedInstallmentBreakdown?.find(
              (b) => b.installmentCount === installmentCount
            );
            currentMonthlyCost = selectedInstallmentCostDetail ? selectedInstallmentCostDetail.totalCost : 0;
          }

          let selectedPaymentMethod = null;

          const renderPaymentMethod = (
            <>
              <Field
                name="paymentMethod"
                component={SelectorFinalFormAdapter}
                validate={required}
                fluid
                buttons={[
                  {
                    label: 'eCheck ACH',
                    subLabel: <String string={`${PAYMENTS_FEE_CONST.ACH_FEE} • 3-5 business days`} />,
                    key: MaintenancePaymentMethod.Check,
                  },
                  {
                    label: 'Credit Card',
                    subLabel: `${PAYMENTS_FEE_CONST.CREDIT_CARD_FEE} Fee`,
                    key: MaintenancePaymentMethod.CreditCard,
                  },
                ]}
                labelClassName={cx('label')}
                buttonClassName={cx('button')}
              />
              {paymentMethod === MaintenancePaymentMethod.Check && (
                <div className={cx('payment-method')}>
                  <PaymentMethodContainer
                    forcePaymentMethod={PaymentMethod.Ach}
                    paymentType={PaymentPreference.Payout}
                    primaryOnly
                    showPriority={false}
                  />
                  <div className={cx('payment-method-text')}>
                    <String string={POST_INSPECTION_FLOW_STRINGS['approvals.paymentplan.ach.subtext']} />
                  </div>
                </div>
              )}
              {paymentMethod === MaintenancePaymentMethod.CreditCard && (
                <div className={cx('payment-method')}>
                  <PaymentMethodContainer
                    forcePaymentMethod={PaymentMethod.CreditCard}
                    paymentType={PaymentPreference.Maintenance}
                    primaryOnly
                    showPriority={false}
                  />
                </div>
              )}
            </>
          );

          if (paymentMethod === MaintenancePaymentMethod.Check) {
            selectedPaymentMethod = fullUser?.getPaymentMethodFor(
              PaymentPreference.Payout,
              PaymentPriority.Primary,
              PaymentMethod.Ach
            );
          } else if (paymentMethod === MaintenancePaymentMethod.CreditCard) {
            selectedPaymentMethod = fullUser?.getPaymentMethodFor(
              PaymentPreference.Maintenance,
              PaymentPriority.Primary,
              PaymentMethod.CreditCard
            );
          }

          return (
            <form onSubmit={formSubmit}>
              {currentTab === CustomMaintenancePaymentPlan.FromRent && (
                <>
                  <Field
                    name="installmentCount"
                    component={SelectorFinalFormAdapter}
                    validate={required}
                    buttons={[
                      {
                        label: "From 1st Month's Rent",
                        subLabel: '1% fee ($20 minimum)',
                        key: 1,
                      },
                      ...monthlyRewards.map((x) => ({
                        label: `Over ${x.value}-Months`,
                        subLabel: '1% fee/month ($20 minimum)',
                        key: x.value,
                        disabled: false,
                        locked: false,
                      })),
                    ]}
                    labelClassName={cx('label')}
                    buttonClassName={cx(['button', 'payment-plan'])}
                    className={cx('payment-plan-wrapper')}
                  />
                  <Space.XXL />

                  {hasUpfrontAmount && (
                    <>
                      <Box textAlign="center" marginBottom="lg">
                        <Box marginBottom="xs">
                          <LegacyText fontSize="h3" fontWeight="semibold">
                            How would you like to cover the down payment?
                          </LegacyText>
                        </Box>
                        <Box>
                          <LegacyText fontSize="body">
                            You will be charged for 25% of the improvements upfront.
                          </LegacyText>
                        </Box>
                      </Box>
                      {renderPaymentMethod}
                    </>
                  )}
                </>
              )}
              {currentTab === CustomMaintenancePaymentPlan.UponCompletion && renderPaymentMethod}

              <div className={cx('summary')}>
                <div className={cx('left')} />
                <div className={cx('right')}>
                  <div className={cx('title')}>
                    {currentMonthlyCost ? (
                      <>
                        <Money.DOLLAR_CENTS cash={currentMonthlyCost} />
                        /mo.
                      </>
                    ) : (
                      <Money.DOLLAR_CENTS cash={totalCost} />
                    )}
                  </div>
                  {!!currentMonthlyCost && <div className={cx('subtitle')}>Deducted From Rent</div>}
                  {!currentMonthlyCost && <div className={cx('subtitle')}>Upon Completion</div>}
                  {hasUpfrontAmount && (
                    <>
                      <Money.DOLLAR_CENTS cash={homeownerPayment.estimatedUpfrontAmount} />
                      {` Due Now (25%)`}
                    </>
                  )}
                </div>
              </div>

              <div className={cx('bottom-button')}>
                <div className={cx('text')}>
                  {flaggedItems.length ? (
                    <Text>{POST_INSPECTION_FLOW_STRINGS['approvals.paymentplan.flag.subtext']}</Text>
                  ) : (
                    <String string={POST_INSPECTION_FLOW_STRINGS['approvals.paymentplan.noflag.subtext']} />
                  )}
                </div>
                <Button
                  disabled={invalid || !selectedPaymentMethod}
                  type="submit"
                  label="Get to work, Belong!"
                  size={BUTTON_SIZES.FLUID_LONG_RESPONSIVE}
                />
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};
