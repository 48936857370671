import { __assign } from '../node_modules/tslib/tslib.es6.mjs.js';
import { format } from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';

var zonedTimeToUtc = dateFnsTz.zonedTimeToUtc,
  formatToTimeZone = dateFnsTz.format,
  utcToZonedTime = dateFnsTz.utcToZonedTime;
function formatDateTime(_a) {
  var dateTime = _a.dateTime,
    format$1 = _a.format,
    options = _a.options;
  if (!dateTime) {
    return '';
  }
  var date = typeof dateTime === 'string' ? new Date(dateTime) : dateTime;
  return format(date, format$1, options);
}
function formatUtcDateTimeToTimeZone(_a) {
  var dateTime = _a.dateTime,
    format = _a.format,
    _b = _a.timeZone,
    timeZone = _b === void 0 ? 'America/Los_Angeles' : _b,
    _c = _a.options,
    options = _c === void 0 ? {} : _c;
  if (!dateTime || !format) {
    return '';
  }
  var mergedOptions = __assign(__assign({}, options), {
    timeZone: timeZone
  });
  return formatToTimeZone(utcToZonedTime(dateTime, timeZone, mergedOptions), format, mergedOptions);
}
function formatZonedDateTimeToUtcDateTime(_a) {
  var dateTime = _a.dateTime,
    format = _a.format,
    _b = _a.timeZone,
    timeZone = _b === void 0 ? 'America/Los_Angeles' : _b,
    _c = _a.options,
    options = _c === void 0 ? {} : _c;
  if (!dateTime) {
    return '';
  }
  return formatDateTime({
    dateTime: zonedTimeToUtc(dateTime, timeZone),
    format: format,
    options: options
  });
}
/**
 * @todo Check that the date is in MM/YY format and throw error if not.
 */
function parseMMYYDateToYYYYMMDDFirstDayOfMonth(_a) {
  var date = _a.date;
  var _b = date.split('/'),
    mm = _b[0],
    yy = _b[1];
  return "20".concat(yy, "-").concat(mm, "-01");
}

export { formatDateTime, formatUtcDateTimeToTimeZone, formatZonedDateTimeToUtcDateTime, parseMMYYDateToYYYYMMDDFirstDayOfMonth };
