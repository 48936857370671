import { useState } from 'react';
import Field from 'components/Field/Field';
import Icon, { ICONS } from 'components/Icon/Icon';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskPhoneNumber, unmaskPhoneNumber } from 'components/Input/masks';
import { SelectorFinalFormAdapter } from 'components/Selector/Selector';
import { isNil } from 'es-toolkit/compat';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import FormCondition, { FIELD_CONDITION_COMPARISON_TYPES } from 'formcomponents/FormCondition/FormCondition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { StepStatus } from 'models/enums';
import AgreementFlowFormLayout from 'pages/AgreementFlow/AgreementFlowFormLayout/AgreementFlowFormLayout';
import PropTypes from 'prop-types';
import { AGREEMENT_FLOW_STRINGS } from 'strings/agreement-flow.strings';
import { required, email, phoneValidation, composeValidators } from 'utils/validation';

const OWNER_TYPES = {
  ME: 'me',
  ANOTHER_INDIVIDUAL: 'another-individual',
  ENTITY: 'entity',
};

const Ownership = ({ currentStepFormData, currentMappedStep, onSubmit, agreement, ...rest }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const multipleHomes = currentMappedStep?.rootData?.homeAgreements?.length > 1;

  const handleSubmit = (values) => {
    const currentUser = currentMappedStep?.data;
    let agreementUser;

    if (values.ownerType === OWNER_TYPES.ME) {
      const {
        agreementUser: { legalEntity, uniqueId },
      } = currentStepFormData;

      agreementUser = {
        uniqueId,
        user: {
          ...currentUser,
        },
        legalEntity: {
          ...legalEntity,
          legalEntityType: 'User',
        },
        isPrimary: true,
      };
    } else if (values.ownerType === OWNER_TYPES.ANOTHER_INDIVIDUAL) {
      agreementUser = {
        user: { ...values.anotherIndividual },
        isPrimary: false,
      };
    } else {
      const {
        agreementUser: {
          legalEntity: { userId },
          uniqueId,
        },
      } = currentStepFormData;

      agreementUser = {
        user: {
          ...currentUser,
        },
        legalEntity: {
          businessName: values.entity.businessName,
          uniqueId,
          userId,
          legalEntityType: 'Business',
        },
        isPrimary: true,
      };

      if (values.entity.entitySelfManaged) {
        agreementUser.user = { ...currentUser };
      } else {
        agreementUser.user = { ...values.entity };
      }
    }

    onSubmit({ agreementUser });
  };

  const getInitialValues = () => {
    const currentUserEmail = currentMappedStep?.data?.email;

    if (!currentStepFormData) {
      return;
    }

    if (currentMappedStep?.status === StepStatus.Created) {
      return { entity: { entitySelfManaged: true } };
    }

    const {
      agreementUser,
      agreementUser: { user },
    } = currentStepFormData;
    const formData = {
      entity: {},
      anotherIndividual: {},
    };

    if (agreementUser?.legalEntity?.legalEntityType === 'Business') {
      formData.ownerType = OWNER_TYPES.ENTITY;
      formData.entity.businessName = agreementUser.legalEntity.name;
      formData.entity.entitySelfManaged = true;
    } else if (user.email === currentUserEmail) {
      formData.ownerType = OWNER_TYPES.ME;
    } else {
      formData.ownerType = OWNER_TYPES.ANOTHER_INDIVIDUAL;
      formData.anotherIndividual = { ...user };
    }

    const initialValues = { ...formData };

    if (isNil(initialValues?.entity?.entitySelfManaged)) {
      initialValues.entity.entitySelfManaged = true;
    }

    return initialValues;
  };

  const validateEmail = (emailValue) => {
    const currentUserEmail = currentMappedStep?.data?.email;

    if (emailValue === currentUserEmail) {
      setErrorMessage(AGREEMENT_FLOW_STRINGS['ownership.email.error']);
      return AGREEMENT_FLOW_STRINGS['ownership.email.error'];
    }
  };

  const renderContactInfoForm = (namePrefix) => (
    <>
      <Row>
        <Col md={6}>
          <Field
            name={`${namePrefix}.firstName`}
            component={InputFinalFormAdapter}
            validate={required}
            placeholder={AGREEMENT_FLOW_STRINGS['ownership.first-name']}
          />
        </Col>
        <Col md={6}>
          <Field
            name={`${namePrefix}.lastName`}
            component={InputFinalFormAdapter}
            validate={required}
            placeholder={AGREEMENT_FLOW_STRINGS['ownership.last-name']}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field
            name={`${namePrefix}.email`}
            component={InputFinalFormAdapter}
            validate={composeValidators(required, email, validateEmail)}
            placeholder={AGREEMENT_FLOW_STRINGS['ownership.email']}
            errorMessage={errorMessage}
          />
        </Col>
        <Col md={6}>
          <Field
            name={`${namePrefix}.phone`}
            placeholder={AGREEMENT_FLOW_STRINGS['ownership.phone']}
            component={InputFinalFormAdapter}
            validate={composeValidators(phoneValidation, required)}
            mask={maskPhoneNumber}
            unmask={unmaskPhoneNumber}
          />
        </Col>
      </Row>
    </>
  );

  const renderAnotherIdividualSection = (formValues) => (
    <FormCondition
      conditions={[
        [
          {
            fieldName: `ownerType`,
            compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
            fieldValue: OWNER_TYPES.ANOTHER_INDIVIDUAL,
          },
        ],
      ]}
      formValues={formValues}
    >
      <FormLayout.Section />
      <FormLayout.Section
        sectionTitle={AGREEMENT_FLOW_STRINGS['ownership.another-individual.section-title']}
        sectionSubTitle={AGREEMENT_FLOW_STRINGS['ownership.another-individual.section-subtitle']}
      >
        {renderContactInfoForm('anotherIndividual')}
      </FormLayout.Section>
    </FormCondition>
  );

  /**
   * We're hidding the fields to indicate that the homeowner is not managing the
   * business entity until we can test it properly and make sure it's working.
   * https://belonghome.atlassian.net/browse/BPRD-3471
   * @param {*} formValues
   * @returns
   */
  const renderEntitySection = (formValues) => (
    <FormCondition
      conditions={[
        [
          {
            fieldName: `ownerType`,
            compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
            fieldValue: OWNER_TYPES.ENTITY,
          },
        ],
      ]}
      formValues={formValues}
    >
      <FormLayout.Section>
        <Row>
          <Col md={6}>
            <Field
              name="entity.businessName"
              component={InputFinalFormAdapter}
              validate={required}
              placeholder={AGREEMENT_FLOW_STRINGS['ownership.entity.business-name']}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col md={12}>
            <Field
              name="entity.entitySelfManaged"
              component={CheckboxFinalFormAdapter}
              useOnChangeFormToHandle
              alignWithField={false}
              label={AGREEMENT_FLOW_STRINGS['ownership.entity.self-managed']}
            />
          </Col>
        </Row> */}
      </FormLayout.Section>
      {/* <FormCondition
        conditions={[
          [
            {
              fieldName: `entity.entitySelfManaged`,
              compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
              fieldValue: false,
            },
          ],
        ]}
        formValues={formValues}
      >
        <FormLayout.Section sectionTitle={AGREEMENT_FLOW_STRINGS['ownership.entity.who-is.title']}>
          {renderContactInfoForm(OWNER_TYPES.ENTITY)}
        </FormLayout.Section>
      </FormCondition> */}
    </FormCondition>
  );

  return (
    <AgreementFlowFormLayout
      {...rest}
      currentMappedStep={currentMappedStep}
      onSubmit={handleSubmit}
      title={
        multipleHomes ? AGREEMENT_FLOW_STRINGS['ownership.title.plural'] : AGREEMENT_FLOW_STRINGS['ownership.title']
      }
      subTitle={AGREEMENT_FLOW_STRINGS['ownership.subtitle']}
      initialValues={getInitialValues()}
      getForm={({ form }) => (
        <>
          <FormLayout.Section>
            <Field
              name="ownerType"
              component={SelectorFinalFormAdapter}
              validate={required}
              fluid
              buttons={[
                {
                  label: AGREEMENT_FLOW_STRINGS['ownership.selector.me'],
                  subLabel: AGREEMENT_FLOW_STRINGS['ownership.selector.me.sublabel'],
                  icon: <Icon icon={ICONS.ME.DEFAULT} responsive />,
                  iconSelected: <Icon icon={ICONS.ME.INVERSE} responsive />,
                  key: OWNER_TYPES.ME,
                },
                {
                  label: AGREEMENT_FLOW_STRINGS['ownership.selector.entity'],
                  icon: <Icon icon={ICONS.ENTITY_TRUST.DEFAULT} responsive />,
                  iconSelected: <Icon icon={ICONS.ENTITY_TRUST.INVERSE} responsive />,
                  key: OWNER_TYPES.ENTITY,
                },
                {
                  label: AGREEMENT_FLOW_STRINGS['ownership.selector.another-individual'],
                  icon: <Icon icon={ICONS.ANOTHER_INDIVIDUAL.DEFAULT} responsive />,
                  iconSelected: <Icon icon={ICONS.ANOTHER_INDIVIDUAL.INVERSE} responsive />,
                  key: OWNER_TYPES.ANOTHER_INDIVIDUAL,
                },
              ]}
            />
            {renderAnotherIdividualSection(form.getState().values)}
            {renderEntitySection(form.getState().values)}
          </FormLayout.Section>
        </>
      )}
    />
  );
};

Ownership.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  currentStepFormData: PropTypes.object,
  currentMappedStep: PropTypes.object,
  agreement: PropTypes.object,
};

Ownership.defaultProps = {
  currentStepFormData: null,
  currentMappedStep: null,
  agreement: null,
};

Ownership.defaultProps = {};

export default Ownership;
