import React from 'react';
import SetHeightAnimation from 'animations/SetHeightAnimation/SetHeightAnimation';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { BUTTON_TYPES } from 'components/Button/buttonTypes';
import { includes, xor } from 'es-toolkit/compat';
import PropTypes from 'prop-types';
import styles from './TimeList.module.css';

const cx = classNames.bind(styles);

const DATE_SELECTOR_MODE = {
  SINGULAR: 'SINGULAR',
  MULTIPLE: 'MULTIPLE',
};

const TimeList = ({ customCallBack, highlightArray, listOfTimes, mode, error, smallButtons }) => {
  return (
    <div className={cx('wrapper')}>
      {listOfTimes.map((values) => {
        const { value, label, disabled, hidden } = values;
        return (
          !hidden && (
            <Button
              key={value}
              className={cx(
                'timeButton',
                'timeButtonMargin',
                {
                  highlight: !!includes(highlightArray, value),
                },
                { error: !!error },
                { disabled },
                { smallButtons }
              )}
              buttonType={BUTTON_TYPES.NOSTYLE}
              onClick={() => {
                if (mode === DATE_SELECTOR_MODE.MULTIPLE) {
                  const valuesToChange = xor(highlightArray, [value]);
                  customCallBack(valuesToChange);
                } else {
                  if (highlightArray[0] === value) {
                    return customCallBack([]);
                  }
                  customCallBack([value]);
                }
              }}
            >
              {label}
            </Button>
          )
        );
      })}
    </div>
  );
};

TimeList.propTypes = {
  customCallBack: PropTypes.func.isRequired,
  highlightArray: PropTypes.array,
  listOfTimes: PropTypes.array,
  mode: PropTypes.oneOf(Object.values(DATE_SELECTOR_MODE)),
  error: PropTypes.bool.isRequired,
  smallButtons: PropTypes.bool,
};

TimeList.defaultProps = {
  highlightArray: [],
  listOfTimes: [],
  mode: DATE_SELECTOR_MODE.MULTIPLE,
  smallButtons: true,
};

export default SetHeightAnimation(TimeList);
